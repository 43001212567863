<script>
  export let message = "";
  export let action = undefined;
  export let type = "error";
  export let icon = "warning";
</script>

<div
  class="modal up modalMessage {type === 'error'
    ? 'ModalDisconnet'
    : 'ModalInfo'}"
>
  <div
    class="modal-content text-inicial"
    style="overflow-y: hidden; display: block"
  >
    <i class="iconwaring material-icons left iconDIsconet">{icon}</i>
    <div class="contFoot col s12">
      <span class="title {type === 'error' ? 'titlepop' : ''} s12">
        {message}
      </span>
      {#if action}
        <span
          class="title {type === 'error' ? 'titlepop' : ''} col s5"
          style="font-weight: bold; margin-right: 0 !important"
        >
          {action}
        </span>
      {/if}
    </div>
  </div>
</div>

<style>
  .title {
    padding-top: 2px;
    font-size: 14px;
    margin-right: 16px;
  }
  .titlepop {
    color: #212121;
  }
  .modal-content {
    background-color: transparent;
  }
  .contFoot {
    padding-right: 0px;
    padding-bottom: 5px;
    font-size: 14px;
    display: flex;
  }

  .modalMessage {
    height: fit-content !important;
    border-radius: 8px !important;
    box-shadow: 0px 0px 8px #00000029;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
  }
  .ModalDisconnet {
    background-color: #ffdadd !important;
  }
  .ModalInfo {
    background-color: var(--background-color) !important;
  }
  .iconDIsconet {
    color: red !important;
  }

  .modal .modal-content {
    padding: 17px;
  }

  .up {
    display: block !important;
    z-index: 1003 !important;
    width: max-content;
    top: 62px;
  }
  i.left {
    float: left;
    margin-right: 15px;
    margin-left: 5px;
    margin-top: 1px;
  }
  @media only screen and (max-width: 575px) {
    .up {
      top: 77%;
    }
  }
  @media only screen and (max-width: 389px) {
    .titlepop {
      margin-right: 15px;
    }
  }
  @media only screen and (max-width: 373px) {
    .up {
      width: 90%;
    }
    .contFoot {
      display: grid;
    }
    .titlepop {
      margin-right: 0px;
    }
  }
  @media only screen and (min-width: 900) {
    .up {
      width: fit-content;
    }
  }
</style>
