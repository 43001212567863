<script>
  import { createEventDispatcher, onMount } from "svelte";
  //import watermark from "watermarkjs";
  import { _ } from "svelte-i18n";
  import { uploadImgs } from "../service";
  export let propertyInformation;
  export let reservation;
  export let isADesktop;
  const dispatch = createEventDispatcher();
  let signed = false;
  let loading = false;
  let screenHeight = window.screen.height;
  let screenWidth = window.screen.width;
  let estaDibujando = false;
  let vectorX = 0;
  let vectorY = 0;
  let canvas;
  let contenedor;
  let rect;

  onMount(() => {
    canvas = document.querySelector("#micanvas");
    contenedor = canvas.getContext("2d");
    rect = setInitialCanvas(canvas, contenedor);
    window.addEventListener("resize", onResize);

    // Eventos del Mouse
    canvas.addEventListener("mousemove", onMouseMove);
    canvas.addEventListener("mousedown", onMouseDown);
    canvas.addEventListener("mouseup", () => (estaDibujando = false));
    canvas.addEventListener("mouseout", () => (estaDibujando = false));

    /* Usar Canvas en Dispositivos Móviles */
    // Dibujar al tocar la pantalla del dispositivo móvil
    document.body.addEventListener("touchstart", touchStart, false);
    // No Dibujar al dejar de tocar la pantalla del dispositivo móvil
    document.body.addEventListener("touchend", touchEnd, false);
    // Permitir desplazarse en la pantalla del dispositivo móvil
    document.body.addEventListener("touchmove", touchMove, false);
    document.querySelector("#restart").addEventListener("click", onRestart);
  });

  const nextStage = () => {
    const canvas = document.querySelector("#micanvas");
    loading = true;
    uploadImgs({
      data: canvas.toDataURL(),
      propertyId: propertyInformation.id,
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      )
      .then((res) => {
        reservation.signature = res.urlToPreview;
        loading = false;
        dispatch("nextStage", "nextStage");
      })
      .catch((e) => {
        console.error(e);
        loading = false;
      });
  };

  const isSigned = () =>
    contenedor
      ?.getImageData(0, 0, canvas.width, canvas.height)
      .data.some((channel) => channel !== 0);

  const setInitialCanvas = (canvas, contenedor) => {
    var rect = canvas.getBoundingClientRect();
    canvas.height = rect.height;
    canvas.width = rect.width;

    // Ancho del Trazo
    contenedor.lineWidth = 5;

    // Trazo redondeado
    contenedor.lineCap = "round";
    contenedor.linJoin = "round";

    // Color del trazo del dibujo en el Canvas
    contenedor.strokeStyle = "#000000";
    return rect;
  };

  const dibujo = (evento) => {
    if (!estaDibujando) return;
    contenedor.beginPath();
    contenedor.moveTo(vectorX, vectorY);
    // Se traza una línea desde el inicio
    contenedor.lineTo(evento.offsetX - rect.left, evento.offsetY - rect.top);
    // Dibujamos las líneas
    contenedor.stroke();
    [vectorX, vectorY] = [
      evento.offsetX - rect.left,
      evento.offsetY - rect.top,
    ];
    signed = isSigned();
  };

  const onResize = () => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const previousImage = contenedor.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );
    contenedor.scale(ratio, ratio);
    rect = setInitialCanvas(canvas, contenedor);
    contenedor.putImageData(previousImage, 0, 0);
    canvas.removeEventListener("mousedown", onMouseDown);
    canvas.removeEventListener("mousemove", onMouseMove);
    canvas.addEventListener("mousemove", onMouseMove);
    canvas.addEventListener("mousedown", onMouseDown);
    onRestart();
    signed = isSigned();
  };

  const onMouseMove = (evento) => {
    if (evento.target == canvas) {
      dibujo({
        offsetX: evento.offsetX + rect.left,
        offsetY: evento.offsetY + rect.top,
      });
    }
  };

  const onMouseDown = (evento) => {
    // Permitir dibujar cuando se presiona el botón del mouse
    if (evento.target == canvas) {
      estaDibujando = true;
      dibujo(evento.offsetX, evento.offsetY);
    }
  };

  const touchMove = (evento) => {
    if (evento.target == canvas) {
      evento.offsetX = evento.targetTouches[0].clientX;
      evento.offsetY = evento.targetTouches[0].clientY;
      dibujo(evento);
    }
  };
  const touchStart = (evento) => {
    if (evento.target == canvas) {
      estaDibujando = true;
      dibujo(evento.touches[0].clientX, evento.touches[0].clientY);
    }
  };
  const touchEnd = (evento) => {
    if (evento.target == canvas) estaDibujando = false;
  };
  const onRestart = () => {
    contenedor.clearRect(0, 0, canvas.width, canvas.height);
    signed = isSigned();
  };
</script>

<section class="container">
  <div class="my-container-check-in horizontal-view">
    <div class="row">
      <div class="col-12">
        <h1 class="card-title">{$_("Sign bellow")}</h1>
        <div
          class="card {!isADesktop && screenHeight < screenWidth
            ? 'card-horizontal'
            : ''} "
        >
          <div class="card-body">
            <div class="sign-instrucction">
              {#if isADesktop}
                {$_("Use the mouse to enter your signature")}
                <!-- {:else if !isADesktop && screenHeight > screenWidth}
                {$_("To facilitate the process, rotate the device")} -->
              {/if}
            </div>
            <div style="margin:auto">
              <canvas id="micanvas" class="drawingCanvas" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="my-footer-button">
  <div class="row">
    <div class="col-12 offset-md-6 col-md-6 col-adjust">
      <button
        type="button"
        class="btn btn-lg btn-block btn-alt btn-adjust"
        id="restart"
        disabled={loading}
      >
        {$_("Clean")}
      </button>
      <button
        type="button"
        class="btn btn-primary btn-lg btn-block"
        disabled={!signed || loading}
        on:click={nextStage}
      >
        {$_("Continue")}
      </button>
    </div>
  </div>
</div>

<style>
  .card-body {
    height: 45vh;
    width: 100%;
    background-color: white;
  }

  .card-horizontal {
    top: 50%;
    transform: translateY(-50%);
  }
  .card-title {
    font-size: 32px;
  }
  .drawingCanvas {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
    max-height: 100%;
    width: 100%;
    touch-action: none;
    aspect-ratio: 6/4;
  }
  .sign-instrucction {
    position: absolute;
    color: #212121;
    border-top: 1px solid #707070;
    pointer-events: none;
    top: 70%;
    width: 55%;
    transform: translateX(-50%);
    left: 50%;
    text-align: center;
  }

  @media (max-width: 620px) {
    .card-body {
      height: 34vh;
      width: 100%;
      background-color: white;
    }
  }

  @media (max-width: 820px) {
    .offset-md-6 {
      margin-left: 0%;
    }
    .col-md-6 {
      width: 100%;
    }
  }
  @media (min-width: 821px) {
    .offset-md-6 {
      margin-left: 67%;
    }
    .col-md-6 {
      width: 33%;
    }
  }
  @media (max-height: 555px) {
    .horizontal-view {
      margin: 0;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      position: fixed;
      top: -3%;
    }
  }
</style>
