<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { headerInformation, goBack } from "../header/service";
  import ReloadingComponent from "../components/reloading-component.svelte";
  import { redirect } from "../Router";
  import { isAuthenticated, user } from "../authService";
  import { environments } from "../assets/environments";

  let loading = true;
  export let propertyInformation;
  export let guestAppFeatures;
  export let guestAppSettings;

  onMount(() => {
    initComponent();
  });

  const initComponent = () => {
    loading = true;
    goBack.set(null);
    headerInformation.set(null);
    headerInformation.set({
      title: propertyInformation.name,
      logo: propertyInformation.logo,
    });
    if (!window.location.host.includes("localhost")) {
      window.location.href = environments.reservationsWeb
        .concat("/#/properties/")
        .concat(propertyInformation.id)
        .concat("/");
    }
    loading = false;
  };

  const goToFeature = (feature) => {
    redirect(`/${propertyInformation.id}/${feature.id}`);
  };
</script>

{#if loading}
  <ReloadingComponent />
{:else}
  <section class="container">
    <h3>
      {$_("Hola")}
      {#if $isAuthenticated}
        {user.firstName} {user.lastName}
      {/if}
    </h3>

    <div class="card mb-3">
      <a href="/{propertyInformation.id}/my-requests" class="btn btn-link">
        Ir a mis solicitudes
      </a>
    </div>

    <div class="row">
      {#each guestAppFeatures as feature}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          class={feature.settings.row == 2 ? "col-12" : "col-6"}
          on:click={() => goToFeature(feature)}
        >
          <div
            class="card ga-card-home {feature.codename == 'requests.create'
              ? 'background-dinamyc-color'
              : ''}"
          >
            <div class={feature.settings.row == 2 ? "row" : ""}>
              <div
                class="{feature.settings.row == 2
                  ? 'col-6'
                  : ''} card-imagen-ga"
              >
                <img
                  src={feature.imgUrl}
                  class="card-img-top {feature.imgUrl.includes('.svg')
                    ? 'card-img-top-svg'
                    : ''} text-center {feature.imgUrl.includes('.svg')
                    ? 'svg-color-' +
                      (feature.codename == 'requests.create'
                        ? guestAppSettings.colorLetter
                        : guestAppSettings.color
                      ).replace('#', '')
                    : ''}"
                  alt="..."
                />
              </div>
              <div class={feature.settings.row == 2 ? "col-6" : ""}>
                <div class="card-body">
                  {#if feature.settings.row == 2 && feature.question}
                    <p class="card-text text-center colorLetter">
                      {feature.question}
                    </p>
                  {/if}
                  <h5 class="card-title text-center colorLetter">
                    {feature.title}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/each}
    </div>
  </section>
{/if}

<style>
  .card.ga-card-home .card-imagen-ga {
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card.ga-card-home .card-imagen-ga .card-img-top {
    width: 100%;
    height: 97px;
  }
  .card.ga-card-home .card-imagen-ga .card-img-top.card-img-top-svg {
    width: 101px;
    height: 97px;
  }
</style>
