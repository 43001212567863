
import { writable } from "svelte/store";

export const openLogin = writable(false);
export const headerInformation = writable(null);
export const goBack = writable(null);
export const backInCurrentPage = writable(false);
export const onClickBackEvent = writable(null);
export const showMenu = writable(true);

goBack.subscribe(back => {
    if (!back) {
        backInCurrentPage.set(!back ? false : $backInCurrentPage);
    }
});
