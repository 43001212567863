<script>
  import { _ } from "svelte-i18n";
  import { onMount, createEventDispatcher } from "svelte";
  import TakePicture from "../../../components/take-picture.svelte";
  import CardPhoto from "./card-photo.svelte";
  export let propertyInformation;
  export let request;

  const dispatch = createEventDispatcher();
  let doYouHavePermissionToUseTheCamera = true;
  let uploadPhoto = null;

  onMount(() => {
    navigator.permissions.query({ name: "camera" }).then((result) => {
      if (result.state === "granted") {
        doYouHavePermissionToUseTheCamera = true;
      } else if (result.state === "prompt") {
        doYouHavePermissionToUseTheCamera = true;
      } else {
        doYouHavePermissionToUseTheCamera = false;
      }
    });
  });

  const loadPicture = (data) => {
    if (uploadPhoto == "back") {
      request.documentImagenBack = data.detail;
    } else {
      request.documentImagenFront = data.detail;
    }
  };
</script>

<div>
  {#if !doYouHavePermissionToUseTheCamera}
    <div class="alert alert-danger" role="alert">
      {$_("You must authorize the permission of the camera")}.
    </div>
  {/if}
</div>

{#if request.errorsDeclined.find((e) => e == "documentFront")}
  <CardPhoto
    on:uploadPhoto={() => {
      uploadPhoto = "front";
      dispatch("onChange", "documentFront");
    }}
    bind:imagen={request.documentImagenFront}
    text={$_("Photo of the front of the identity card")}
  />
{/if}

{#if request.errorsDeclined.find((e) => e == "documentBack")}
  <CardPhoto
    on:uploadPhoto={() => {
      uploadPhoto = "back";
      dispatch("onChange", "documentBack");
    }}
    bind:imagen={request.documentImagenBack}
    text={$_("Photo of the back of the identity card")}
  />
{/if}

{#if uploadPhoto}
  <TakePicture
    bind:propertyInformation
    on:close={() => (uploadPhoto = false)}
    on:ready={loadPicture}
    on:errorPermission={(ev) =>
      (doYouHavePermissionToUseTheCamera = !ev.detail)}
  />
{/if}
