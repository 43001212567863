<script>
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();
    export let imagen;
    export let text;
</script>

<div class="col-12" >
    <a 
        href="#!" style="text-decoration: none; background: none"
        class="card-body"
        on:click|preventDefault={() => !imagen.id ? dispatch("uploadPhoto", "") : null}
    >
        <div>
            <div class="imagen-upload">
                {#if imagen.id}
                    <img class="card-photo" src={imagen.url} alt="" />
                {:else}
                    <span class="text-upload-imagen">
                        {text}
                    </span>
                {/if}
                <div style="display: flex; float: right">
                    {#if imagen.id}
                        <button
                            class="icono-round icono-correct"
                            on:click={() => {
                                imagen = {}
                            }}
                        >
                            <i class="material-icons">delete</i>
                        </button>
                    {/if}
                    <button
                        class="icono-round"
                        on:click={() => dispatch("uploadPhoto", "")}
                    >
                        <i class="material-icons">
                            {#if imagen.id}
                                edit
                            {:else}
                                photo_camera
                            {/if}
                        </i>
                    </button>
                </div>
            </div>
        </div>
    </a>
</div>

<style>
    .imagen-upload {
        cursor: pointer;
    }
    .imagen-upload .text-upload-imagen {
        display: block;
        margin: 25% auto;
        text-align: center;
    }
    .imagen-upload img {
        display: block;
        margin: 0% auto;
    }
    .resize {
        max-width: 900px;
        max-height: 520px;
        margin: auto;
    }
    .card-photo{
        inline-size: fit-content;
        inline-size: inherit;
        width: 100%
    }

    .card{
        margin-bottom: 0px;

    }
</style>
