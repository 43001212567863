<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { headerInformation, goBack } from "../header/service";
  import ReloadingComponent from "../components/reloading-component.svelte";
  import { redirect } from "../Router";
  import { getFeatureById } from "./service";

  import PropertyInformationComponent from "../property-information/page.svelte";
  import OutletsComponent from "../outlets/page.svelte";
  import Error404Component from "./error-404.svelte";
  import { environments } from "../assets/environments";

  let loading = true;
  export let propertyInformation;
  export let params;
  let feature;

  onMount(() => {
    initComponent();
  });

  const initComponent = () => {
    loading = true;
    goBack.set(`/${propertyInformation.id}`);
    headerInformation.set(null);
    if (!window.location.host.includes("localhost")) {
      window.location.href = environments.reservationsWeb
        .concat("/#/properties/")
        .concat(propertyInformation.id)
        .concat("/");
    }
    getFeatureById({
      propertyId: propertyInformation.id,
      featureId: $params.featureId,
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      )
      .then((featureData) => {
        feature = featureData;
        headerInformation.set({
          title: feature.title,
          logo: feature.imgUrl,
        });

        loading = false;
      })
      .catch((err) => {
        console.error(err);
        feature = null;
        loading = false;
      });
  };

  const goToFeature = (feature) => {
    redirect(`/${propertyInformation.id}/${feature._id}`);
  };
</script>

{#if loading}
  <ReloadingComponent />
{:else if !loading && feature}
  {#if feature.codename == "property.details"}
    <PropertyInformationComponent fromHome="false" bind:propertyInformation />
  {:else if feature.codename == "room-service.list"}
    <OutletsComponent
      outleType="room-services"
      fromHome="false"
      bind:propertyInformation
    />
  {:else if feature.codename == "pickup"}
    Pickup
  {:else if feature.codename == "restaurants.list"}
    <OutletsComponent
      outleType="restaurants"
      fromHome="false"
      bind:propertyInformation
    />
  {:else if feature.codename == "service.list"}
    <OutletsComponent
      outleType="services"
      fromHome="false"
      bind:propertyInformation
    />
  {:else if feature.codename == "requests.create"}
    Create Request
  {:else if feature.codename == "service"}
    Service
  {:else if feature.codename == "restaurant"}
    Restaurant
  {:else if feature.codename == "other"}
    Other
  {/if}
{:else}
  <Error404Component {propertyInformation} />
{/if}
