<script>
  export let managementColors = null;

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => node.style[p] = v);
  };
</script>

<div class="gueshub-services-reloading">
  <div class="preloader-wrapper big active">
    <div
      class="spinner-layer"
      use:styling={{
        "border-color": managementColors
          ? managementColors.color
          : null,
      }}
    >
      <div class="circle-clipper left">
        <div class="circle" />
      </div>
      <div class="gap-patch">
        <div class="circle" />
      </div>
      <div class="circle-clipper right">
        <div class="circle" />
      </div>
    </div>
  </div>
</div>

<style>
  .gueshub-services-reloading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: 100%;
  }
  .preloader-wrapper.active {
    -webkit-animation: container-rotate 1568ms linear infinite;
    animation: container-rotate 1568ms linear infinite;
}

.preloader-wrapper.big {
    width: 64px;
    height: 64px;
}

.preloader-wrapper {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
}

.active .spinner-layer,
.active .spinner-layer.spinner-blue-only,
.active .spinner-layer.spinner-red-only,
.active .spinner-layer.spinner-yellow-only,
.active .spinner-layer.spinner-green-only {
    opacity: 1;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-color: #26a69a;;
}

.circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

.active .circle-clipper.left .circle {
    -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.circle-clipper.left .circle {
    left: 0;
    border-right-color: transparent !important;
    -webkit-transform: rotate(129deg);
    transform: rotate(129deg);
}

.circle-clipper .circle {
    width: 200%;
    height: 100%;
    border-width: 3px;
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.circle {
    border-radius: 50%;
}

.gap-patch {
    position: absolute;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}

.gap-patch .circle {
    width: 1000%;
    left: -450%;
}

@keyframes container-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }
    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }
    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }
    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }
    100% {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes left-spin {
    0% {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@keyframes right-spin {
    0% {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }

    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    100% {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}
</style>
