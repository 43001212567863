<script>
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";

  export let title = "";
  export let message = "";
  export let confirmButton = "Confirm";
  export let cancelButton = "Cancel";
  export let warning = false;
  const dispatch = createEventDispatcher();
</script>

<section>
  <div class="my-overlay-sidebar" />
  <div class="modal-show">
    <div class="space title-section">{$_(title)}</div>
    <div class="space">{$_(message)}</div>
    <div>
      <div class="button-section">
        <button
          type="button"
          class="btn btn-alt btn-block btn-adjust-alt"
          on:click={() => dispatch("onCancel")}
        >
          {$_(cancelButton)}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-block {warning
            ? 'appWarning'
            : 'appSettingColor'}"
          on:click={() => dispatch("onConfirm")}
        >
          {$_(confirmButton)}
        </button>
      </div>
    </div>
  </div>
</section>

<style>
  .appSettingColor {
    background-color: var(--dynamic-color);
  }
  .appWarning {
    background-color: #ff4855 !important;
    color: #fff !important;
  }
  .modal-show {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: var(--background-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--dropdown-boxShadow);
    padding: 32px 24px 32px 24px;
    border-radius: 16px;
    width: 360px;
    z-index: 10;
  }
  .btn-adjust-alt {
    width: 90%;
  }
  .button-section {
    display: flex;
    width: 75%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
  .title-section {
    font-weight: 700;
    font-size: 20px;
  }
  .space {
    margin-bottom: 16px;
  }
  @media (max-width: 359px) {
    .modal-show {
      width: 95% !important;
    }
  }
</style>
