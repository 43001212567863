<script>
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import {
    headerInformation,
    goBack,
    backInCurrentPage,
    onClickBackEvent,
    showMenu,
  } from "../header/service";
  import { getReservationInformation, postNewCheckin } from "./service";

  import WelcomeComponent from "./stages/welcome.svelte";
  import DetailSummaryComponent from "./stages/detail-summary.svelte";
  import DataComponent from "./stages/data.svelte";
  import DocumentsComponent from "./stages/documents.svelte";
  import CompanionsComponent from "./stages/companions.svelte";
  import CompanionsDataComponent from "./stages/companions-data.svelte";
  import TermConditionsComponent from "./stages/term-conditions.svelte";
  import SignatureComponent from "./stages/signature.svelte";
  import ReloadingComponent from "../components/reloading-component.svelte";

  export let propertyInformation;
  export let guestAppFeatures;
  export let guestAppSettings;
  export let params;
  export let queryParams;

  let loading = true;
  let isADesktop = false;
  const stages = [
    "welcome",
    //"detail-summary", Por ahora no estará disponible esta vista.
    "data",
    "documents",
    "companions",
    "companions-data",
    "term-conditions",
    "signature",
  ];
  let currentStage = 0;
  let reservation = null;
  let readOnly = $queryParams.var ? $queryParams.var == "iframe" : false;

  const detectIsADesktop = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      isADesktop = false;
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      isADesktop = false;
    } else {
      isADesktop = true;
    }
    document
      .querySelector(":root")
      ?.style.setProperty("--font-label", isADesktop ? "0.75rem" : "0.7rem");
  };

  onMount(() => {
    detectIsADesktop();
    window.onresize = () => setTimeout(() => detectIsADesktop(), 100);

    initComponent();
  });

  const initComponent = () => {
    loading = true;
    showMenu.set(false);
    goBack.set(null);
    headerInformation.set({
      title: propertyInformation.name,
      logo: propertyInformation.logo,
    });

    if ($queryParams.var && $queryParams.var == "iframe") {
      switch ($queryParams.view) {
        case "home":
          currentStage = 0;
          break;
        case "form":
          currentStage = 1;
          break;
        case "terms-and-conditions":
          currentStage = 4;
          break;

        default:
          break;
      }
    }

    getReservationInformation({
      propertyInformation: propertyInformation,
      code: $queryParams.code || "general",
      channel: $queryParams.channel || null,
    }).then((reservationInformation) => {
      if (reservationInformation.urlRedirect) {
        window.location.href = reservationInformation.urlRedirect;
      } else {
        reservation = Object.assign({}, reservationInformation, {
          stage: stages[currentStage],
        });

        loading = false;

        onClickBackEvent.subscribe((backInCurrentPageResponse) => {
          if (backInCurrentPageResponse) {
            backStage();
          }
        });
      }
    });
  };

  const backStage = () => {
    /*if (
        reservation.reservationValid ||
        (!reservation.reservationValid && currentStage - 1 != 1)
      ) {*/
    if (currentStage == 5 && reservation.companion != null) {
      currentStage -= 2;
    }
    if (currentStage == 5 && reservation.companions.length == 0) {
      --currentStage;
    }
    --currentStage;
    /*} else {
        currentStage -= 2;
      }*/
    if (currentStage == 0) {
      goBack.set(null);
      backInCurrentPage.set(false);
    } else {
      goBack.set(`/${propertyInformation.id}`);
    }
    reservation.stage = stages[currentStage];
  };

  const nextStage = () => {
    if (currentStage + 1 <= stages.length - 1) {
      /*if (
        reservation.reservationValid ||
        (!reservation.reservationValid && currentStage + 1 != 1)
      ) {*/
      if (currentStage == 2 && reservation.companion != null) {
        ++currentStage;
      }
      if (currentStage == 3 && reservation.companions.length == 0) {
        ++currentStage;
      }
      ++currentStage;
      /*} else {
        currentStage += 2;
      }*/
      reservation.stage = stages[currentStage];
      if (currentStage > 0) {
        goBack.set(`/${propertyInformation.id}`);
        backInCurrentPage.set(true);
      } else {
        goBack.set(null);
      }
    } else {
      loading = true;
      postNewCheckin({
        propertyId: propertyInformation.id,
        body: reservation,
      })
        .then((Response) =>
          Response.ok ? Response.json() : Promise.reject(Response)
        )
        .then((Response) => {
          window.location.href = Response.urlRedirect;
        })
        .catch((err) => {
          console.error(err);
          loading = false;
          currentStage = 1;
          reservation.stage = stages[currentStage];
        });
    }
  };
</script>

<!-- {#if isADesktop}
  <QrRedirect /> -->
{#if loading}
  <ReloadingComponent bind:managementColors={guestAppSettings} />
{:else if reservation.stage == "welcome"}
  <WelcomeComponent
    bind:propertyInformation
    bind:guestAppSettings
    bind:readOnly
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "detail-summary"}
  <DetailSummaryComponent
    bind:propertyInformation
    bind:guestAppSettings
    bind:reservation
    bind:readOnly
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "data"}
  <DataComponent
    bind:propertyInformation
    bind:guestAppSettings
    bind:reservation
    bind:isADesktop
    bind:readOnly
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "documents"}
  <DocumentsComponent
    bind:reservation
    bind:propertyInformation
    bind:guestAppSettings
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "companions"}
  <CompanionsComponent
    bind:guestAppSettings
    bind:reservation
    bind:isADesktop
    bind:propertyInformation
    bind:currentStage
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "companions-data"}
  <CompanionsDataComponent
    bind:guestAppSettings
    bind:reservation
    bind:isADesktop
    bind:propertyInformation
    bind:currentStage
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "term-conditions"}
  <TermConditionsComponent
    bind:propertyInformation
    bind:isADesktop
    bind:reservation
    on:nextStage={nextStage}
  />
{:else if reservation.stage == "signature"}
  <SignatureComponent
    bind:reservation
    bind:isADesktop
    bind:propertyInformation
    on:nextStage={nextStage}
  />
{/if}
