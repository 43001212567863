<script>
  import { createEventDispatcher } from "svelte";
  import { format } from "date-fns";
  import { _ } from "svelte-i18n";
  // export let propertyInformation;
  export let guestAppSettings;
  export let reservation;
  const dispatch = createEventDispatcher();

  const nextStage = () => {
    dispatch("nextStage", "nextStage");
  };
</script>

<section class="container">
  <div class="my-container-check-in">
    <div class="row">
      <h1 class="card-title">{$_("Reservation details")}</h1>
      <div class="col-12">
        <div class="card cont__card__date">
          <div class="card-body cont__card">
            <div class="row cont__check__dates">
              <div class="wd__0">
                <span class="sub__date">CHECK-IN</span>
                <h2 class="title__date">
                  {reservation.arrivalDate
                    ? format(new Date(reservation.arrivalDate), "dd MMM yyyy")
                    : ""}
                </h2>
                <span class="sub__date"
                  >{$_("ARRIVAL")} {reservation.checkInHour}</span
                >
              </div>
              <div class="col-2 cont__icon">
                <i
                  style="color: {guestAppSettings.color}; font-size: 32px;"
                  class="material-icons">keyboard_arrow_right</i
                >
              </div>
              <div class="wd__0">
                <span class="sub__date">CHECKOUT</span>
                <h2 class="title__date">
                  {reservation.departureDate
                    ? format(new Date(reservation.departureDate), "dd MMM yyyy")
                    : ""}
                </h2>
                <span class="sub__date"
                  >{$_("DEPERTURE")} {reservation.checkOutHour}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body card__reservations">
            <div class="card__title__reser">
              <div
                class="img-reserv"
                style="background-color: {guestAppSettings.color};"
              >
                <img
                  src="/images/reservation.svg"
                  alt=""
                  width="40"
                  class="img__reserva svg-color-{guestAppSettings.colorLetter.replace(
                    '#',
                    ''
                  )}"
                />
              </div>
              <span class="txt__title__reserva"> Reserva</span>
            </div>
            <div class="cont__type__room">
              <span class="txt__type__room">
                Penthouse
                <span style="float: right;"> $20 </span>
              </span>
            </div>
            <div class="cont__persons">
              <span class="txt__name__persons">
                {reservation.firstName}
                {reservation.lastName}
                <span class="txt__persons">
                  <i class="material-icons icon__persons">person</i>
                  2
                </span>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="card__reservations cont__subtotal">
              <span class="txt__subtotal">
                SUBTOTAL
                <span style="float: right;">
                  $ {parseFloat(reservation.rateAmount).toFixed(2)}</span
                >
              </span>
            </div>
          </div>
        </div>

        {#each reservation.rooms as room}
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <h6>{room.type}</h6>
                  {#if room.companion}
                    <span>Acompañante</span>
                  {/if}
                </div>
                <div class="col-6 text-end">
                  <h6>{room.price}</h6>
                </div>
              </div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</section>

<div class="my-footer-button">
  <div class="row">
    <div class="col-12 offset-md-9 col-md-3">
      <button
        type="button"
        class="btn btn-primary btn-lg btn-block"
        on:click={nextStage}
      >
        {$_("Continue")}
      </button>
    </div>
  </div>
</div>

<style>
  .my-container-check-in {
    margin: 20% auto;
  }
  .my-container-check-in .img-reserv {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
  }

  .cont__check__dates {
    width: 100%;
    justify-content: space-between;
  }

  .cont__card {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .cont__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wd__0 {
    width: auto;
  }

  .cont__card__date {
    border-radius: 8px;
    border-color: transparent !important;
  }

  .sub__date {
    font-size: 10px;
    font-weight: 600;
  }
  .title__date {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: -4px;
    margin-top: 1px;
  }

  .txt__title__reserva {
    font-size: 16px;
    font-weight: 700;
    margin-left: 16px;
  }

  .img__reserva {
    width: 32px;
    height: 32px;
  }

  .cont__card {
    padding: 16px 4px 16px 4px !important;
  }

  .card__reservations {
    padding: 16px 16px 16px 16px !important;
  }

  .card__title__reser {
    display: flex;
    align-items: center;
  }

  .txt__type__room {
    font-size: 14px;
    font-weight: 600;
  }

  .cont__persons {
    margin-top: 6px;
  }

  .cont__type__room {
    margin-top: 22px;
  }

  .txt__persons {
    border-radius: 4px;
    padding: 6px 6px 5px 6px;
    font-size: 12px;
    margin-left: 2px;
    font-weight: 700;
  }

  .icon__persons {
    font-size: 18px;
    position: relative;
    left: -1px;
    top: 3px;
  }

  .txt__name__persons {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .txt__subtotal {
    font-size: 14px;
    font-weight: 700;
  }

  .card-title {
    font-size: 20px;
  }

  .my-container-check-in {
    margin: 16px auto;
  }

  .wd__0 {
    padding: 0px;
  }

  .card {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
    border-color: #f7eded;
  }

  @media only screen and (min-width: 601px) {
    .sub__date {
      font-size: 12px;
    }

    .title__date {
      font-size: 24px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .cont__card {
      padding: 16px 17px 16px 17px !important;
    }

    .card__reservations {
      padding: 16px 32px 16px 32px !important;
    }

    .txt__title__reserva {
      font-size: 24px;
    }

    .img__reserva {
      width: 50px;
      height: 50px;
    }

    .txt__type__room {
      font-size: 16px;
    }

    .txt__subtotal {
      font-size: 16px;
    }

    .card-title {
      margin-bottom: 32px;
      font-size: 32px;
    }

    .my-container-check-in {
      margin: 38px auto;
    }
  }

  @media only screen and (min-width: 1200px) {
    .my-container-check-in {
      margin: 85px auto;
    }

    .sub__date {
      font-size: 12px;
    }

    .title__date {
      font-size: 32px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .cont__card {
      padding: 16px 17px 16px 17px !important;
    }

    .card__reservations {
      padding: 16px 32px 16px 32px !important;
    }

    .txt__title__reserva {
      font-size: 24px;
    }

    .img__reserva {
      width: 50px;
      height: 50px;
    }

    .txt__type__room {
      font-size: 16px;
    }

    .txt__subtotal {
      font-size: 16px;
    }

    .card-title {
      margin-bottom: 32px;
      font-size: 32px;
    }
  }
</style>
