<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { useForm, required, validators, email, Hint } from "svelte-use-form";
  import { limitField, checkInvalidForm, omit } from "../../commons";
  import ModalError from "../../components/modal-error.svelte";
  import ModalConfirmation from "../../components/modal-confirmation.svelte";
  // export let propertyInformation;
  export let guestAppSettings;
  export let reservation;
  export let isADesktop;
  export let currentStage;
  let infoAlert = false;
  let showConfirmation = false;
  let current;
  const CompanionForm = useForm();
  const dispatch = createEventDispatcher();

  onMount(() => {
    reservation.companions = reservation.companions.map((comp, i, a) => {
      const comId = a[i - 1]?.type == comp.type ? a[i - 1]?.companionId : 0;
      return {
        ...comp,
        index: i,
        companionId: comId ? comId + 1 : 1,
      };
    });
    setTimeout(
      () =>
        document
          .querySelector("#continue")
          .addEventListener("click", () => ($CompanionForm.touched = true)),
      100
    );
  });

  function formLabel(type) {
    const child = {
      name: "Child's name",
      lastName: "Child's last name",
      phone: "Legal guardian's phone number",
      email: "Legal guardian's email",
    };
    return {
      adult: {
        name: "Name",
        lastName: "Last name",
        phone: "Phone number",
        email: "email",
      },
      child,
      baby: child,
    }[type];
  }

  const removeCompanion = (companion) => {
    reservation.companions = reservation.companions
      .filter((comp) => comp.index !== companion.index)
      .map((comp, i, a) => {
        const comId = a[i - 1]?.type == comp.type ? a[i - 1]?.companionId : 0;
        return {
          ...comp,
          index: i,
          companionId: comId ? comId + 1 : 1,
        };
      });
    infoAlert = true;
    setTimeout(() => (infoAlert = false), 2000);
    showConfirmation = false;
    current = undefined;
    if (reservation.companions.length === 0) {
      reservation.stage = "companions";
      currentStage = 3;
    }
  };

  const nextStage = () => {
    reservation.companions = reservation.companions.map((cmp) => omit("index", cmp));
    dispatch("nextStage", "nextStage");
  };
</script>

<section class="container">
  <div class="my-container-check-in">
    <form id="CompanionForm" use:CompanionForm>
      {#each reservation.companions as companion, index}
        <div class="card">
          <div class="card-body {isADesktop ? 'row' : ''}">
            <div class="companion-title-card">
              <span>
                {companion.type === "child"
                  ? $_("Child")
                  : companion.type === "baby"
                  ? $_("Baby")
                  : $_("Adult")}
                {" " + companion.companionId}
              </span>

              <ul class="navbar-nav mr-auto">
                <div class="dropdown iconLangSelect">
                  <a
                    class="nav-link dropdownLang"
                    href="#!"
                    id="navbarDarkDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i style="color: #686c6d;" class="material-icons">
                      more_vert
                    </i>
                  </a>
                  <ul
                    style="list-style: none; min-width: 8rem; "
                    class="dropdown-menu card-dropdown"
                    aria-labelledby="navbarDarkDropdownMenuLink"
                  >
                    <li
                    class="show"
                      on:keyup={() => null}
                      on:click={() => {
                        current = companion;
                        showConfirmation = true;
                      }}
                    >
                      <i
                        style="color: #686c6d; margin-right:6px;"
                        class="material-icons"
                      >
                        delete
                      </i>
                      <span>{$_('Delete')}</span>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>

            <div class="mb-4 {isADesktop ? 'col-6' : ''}">
              <label
                for="CompanionForm"
                class="form-label {!$CompanionForm[`firstName${index}`]
                  ?.valid && $CompanionForm[`firstName${index}`]?._touched
                  ? 'field-invalid'
                  : ''}"
              >
                {$_(formLabel(companion.type).name)}
              </label>
              <input
                use:validators={[required]}
                type="text"
                class="form-control"
                id="firstName{index}"
                name="firstName{index}"
                autocomplete="given-name"
                placeholder={$_("Enter name")}
                on:input={(ev) => limitField(ev, /[^a-zÀ-ÿñ\s]/gi)}
                bind:value={companion.firstName}
              />
              <Hint
                class="help-block help-block-left field-invalid"
                for="firstName{index}"
                on="required"
              >
                {$_("This field is required")}
              </Hint>
            </div>

            <div class="mb-4 {isADesktop ? 'col-6' : ''}">
              <label
                for="lastName"
                class="form-label {!$CompanionForm[`lastName${index}`]?.valid &&
                $CompanionForm[`lastName${index}`]?._touched
                  ? 'field-invalid'
                  : ''}"
              >
                {$_(formLabel(companion.type).lastName)}
              </label>
              <input
                use:validators={[required]}
                type="text"
                class="form-control"
                id="lastName{index}"
                name="lastName{index}"
                autocomplete="family-name"
                placeholder={$_("Enter last name")}
                on:input={(ev) => limitField(ev, /[^a-zÀ-ÿñ\s]/gi)}
                bind:value={companion.lastName}
              />
              <Hint
                class="help-block help-block-left field-invalid"
                for="lastName{index}"
                on="required"
              >
                {$_("This field is required")}
              </Hint>
            </div>
            <div class="mb-4 {isADesktop ? 'col-6' : ''}">
              <label
                for="phoneNumber"
                class="form-label {!$CompanionForm[`phoneNumber${index}`]
                  ?.valid && $CompanionForm[`phoneNumber${index}`]?._touched
                  ? 'field-invalid'
                  : ''}"
              >
                {$_(formLabel(companion.type).phone)}
              </label>
              <input
                use:validators={[required]}
                type="tel"
                class="form-control"
                id="phoneNumber{index}"
                name="phoneNumber{index}"
                autocomplete="tel"
                placeholder={`${$_("Ex")}: +000000000000`}
                on:input={(ev) => limitField(ev, /[^0-9+]/gi)}
                bind:value={companion.phoneNumber}
              />
              <Hint
                class="help-block help-block-left field-invalid"
                for="phoneNumber{index}"
                on="required"
              >
                {$_("This field is required")}
              </Hint>
            </div>
            <div class="mb-4 {isADesktop ? 'col-6' : ''}">
              <label
                for="email"
                class="form-label {!checkInvalidForm(
                  $CompanionForm[`email${index}`]
                )?.valid && $CompanionForm[`email${index}`]?._touched
                  ? 'field-invalid'
                  : ''}"
              >
                {$_(formLabel(companion.type).email)}
              </label>
              <input
                use:validators={[required, email]}
                type="text"
                class="form-control"
                id="email{index}"
                autocomplete="email"
                name="email{index}"
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                placeholder={$_("Ex: mail@email.com")}
                on:input={(ev) => limitField(ev, /[^a-z0-9-_.@]/gi)}
                bind:value={companion.email}
              />
              <Hint
                class="help-block help-block-left field-invalid"
                for="email{index}"
                on="required"
              >
                {$_("This field is required")}
              </Hint>
            </div>
          </div>
        </div>
      {/each}
    </form>
  </div>
  {#if infoAlert}
    <ModalError
      icon="delete"
      type="info"
      message={$_("A additional guest has been deleted")}
    />
  {/if}
  {#if showConfirmation}
    <ModalConfirmation
      title="Do you want to delete this additional guest's information?"
      message="One additional guest will be removed from the reserve"
      cancelButton="Back"
      confirmButton="Delete"
      warning={true}
      on:onCancel={() => {
        current = undefined;
        showConfirmation = false;
      }}
      on:onConfirm={() => removeCompanion(current)}
    />
  {/if}
</section>

<div class="my-footer-button">
  <div class="row">
    <div class="col-12 offset-md-9 col-md-3" id="continue">
      <button
        type="button"
        class="btn btn-primary btn-lg btn-block"
        disabled={!$CompanionForm.valid}
        on:click={nextStage}
        style="background-color: {guestAppSettings.color};"
      >
        {$_("Continue")}
      </button>
    </div>
  </div>
</div>

<style>
  .companion-title-card {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
    justify-content: space-between;
    display: flex;
  }
  .card-dropdown {
    padding: 10px;

    font-size: 16px;
    top: -16px !important;
    left: -80px !important;
    background: var(--background-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--dropdown-boxShadow);
  }
  .show {
    display: flex !important;
    opacity: 1;
    z-index: 1;
  }
</style>
