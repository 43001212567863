<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import ReloadingComponent from "../components/reloading-component.svelte";
  import { goBack, headerInformation } from "../header/service";
  import { getOutlets } from "./service";
  import { environments } from "../assets/environments";

  export let fromHome = true;
  export let propertyInformation;
  export let outleType = "room-services";
  let loading = true;
  let outlets = [];

  onMount(() => {
    initComponent();
  });

  const initComponent = () => {
    loading = true;
    if (!window.location.host.includes("localhost")) {
      window.location.href = environments.reservationsWeb
        .concat("/#/properties/")
        .concat(propertyInformation.id)
        .concat("/");
    }
    if (fromHome) {
      goBack.set(`/${propertyInformation.id}`);
      headerInformation.set(null);
      headerInformation.set({
        title: "Room service",
        logo: "https://reservations.qa.norelian.com/img/room-service.svg",
      });
    }
    getOutlets({
      propertyId: propertyInformation.id,
      outleType,
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      )
      .then((outletsData) => {
        outlets = outletsData;
        loading = false;
      });
  };
</script>

{#if loading}
  <ReloadingComponent />
{:else}
  <section class="container">
    <div class="row">
      {#each outlets as outlet}
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-cover-img">
              <img src={outlet.cover} class="card-img-top" alt={outlet.name} />
              <img
                src={outlet.photo}
                class="card-img-top photo-logo"
                alt={outlet.name}
              />
            </div>

            <div class="card-body">
              <h5 class="card-title">{outlet.name}</h5>
              <p class="card-text">
                {outlet.description.slice(0, 50)}
                {outlet.description.length > 50 ? "..." : ""}
              </p>
            </div>
          </div>
        </div>
      {/each}
    </div>
  </section>
{/if}

<style>
  .card-cover-img .photo-logo {
    position: absolute;
    right: 0;
    bottom: 92px;
    width: 60px;
  }
</style>
