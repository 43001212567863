import { environments } from "../assets/environments";
import { formDataPost, httpGet, httpPost } from "../http-service";

export const getAllTermsAndConditions = input =>
    httpGet(
        `${environments.guestAppApi}/${input.propertyId}/terms-and-conditions`
    );

export const getCountries = input =>
    httpGet(
        `${environments.guestAppApi}/${input.propertyId}/list-countries`
    )

export const getRoyaltyProgram = input =>
    httpGet(
        `${environments.guestAppApi}/${input.propertyId}/loyalty-program`
    );

export const postNewCheckin = input =>
    httpPost(
        `${environments.guestAppApi}/${input.propertyId}/check-in`,
        input.body
    );

export const uploadImgs = (input) => {
    let data = new FormData();
    data.append("containerName", 'check-in');
    data.append('web', input.data.toString());
    return formDataPost(`${environments.filesApi}/images/${input.propertyId}`, data, {
        reportProgress: true,
        observe: 'events'
    })
}

export const getReservationInformation = input =>
    httpGet(
        `${environments.guestAppApi}/${input.propertyInformation.id}/reservation/${input.code}`
    ).then(
        (response) =>
            response.ok ? response.json() : null
    ).then(response => response.urlRedirect
        ? ({ urlRedirect: response.urlRedirect })
        : Object.assign({}, response, {
            reservationValid: response ? response.reservationValid : false,
            reservationId: response ? response.id : false,
            stage: "welcome", // welcome, detail-summary, data, documents, companions, term-conditions, signature
            checkInHour: input.propertyInformation.hourCheckIn,
            checkOutHour: input.propertyInformation.hourCheckOut,
            channel: input.channel || null
        }));