<script>
  import { onMount } from "svelte";
  import { params } from "../Router";
  import { getEmailTemplate } from "./service";

  let template;
  let footer;
  onMount(() =>{
    params.subscribe((param) =>
      getEmailTemplate({ type: param.type, propertyId: param.propertyId }).then((mailTemplate) => {
        template = mailTemplate.body.find((item) => item.language === param.langCode);
        footer = mailTemplate.footer;
      }));
    document.querySelector(":root").style.setProperty('--color-letter', 'black')
  });
</script>

<section class="transform-page">
    {#if template}
        <iframe class="iframe1" title="" srcdoc="{template?.value + footer }" frameborder="0"></iframe>
    {/if}
</section>

<style>
  .iframe1 {
    position:absolute;
    background-color: white;
    z-index: 8;
    left: 0px;
    width: 100%;
    top: 0px;
    height: 100%;
  }
</style>