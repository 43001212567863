<script>
  import { onMount } from "svelte";
  import {
    useForm,
    required,
    validators,
    email,
    maxLength,
    Hint,
  } from "svelte-use-form";
  import { limitField, checkInvalidForm } from "../../commons";
  import { getCountries } from "../../check-in/service";
  import { _ } from "svelte-i18n";
  import SelectSearchable from "../../components/select-searchable.svelte";
  import ReloadingComponent from "../../components/reloading-component.svelte";
  import DocumentsComponent from "./declined/documents.svelte";
  import { reValidateRequestById } from "../service";

  export let propertyInformation;
  export let guestAppSettings;
  export let request;
  let disabledButton = false;
  let isADesktop = false;
  let showDocumentType = true;
  let loading = true;
  let listCountries = [];
  let listDocumentsTypes = [
    {
      codename: "passport",
      name: $_("Passport"),
      double: false,
    },
    {
      codename: "dni",
      name: $_("Identity card"),
      double: true,
    },
    {
      codename: "licence-driver",
      name: $_("Drive Licence"),
      double: true,
    },
  ];
  let documentDefaultValue = listDocumentsTypes.map((lst) => ({
    ...lst,
    id: lst.codename,
  }))[0];
  let errorsDeclined = [];
  const dataForm = useForm();

  const detectIsADesktop = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      isADesktop = false;
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      isADesktop = false;
    } else {
      isADesktop = true;
    }
    document
      .querySelector(":root")
      ?.style.setProperty("--font-label", isADesktop ? "0.75rem" : "0.7rem");
  };

  const detectedChangeInput = (type) => (e) => {
    errorsDeclined = errorsDeclined.reduce((array, current) => {
      if (type != current) {
        array.push(current);
      }

      return array;
    }, []);
  };

  const dectectedImgChange = (type) => {
    detectedChangeInput(type.detail)();
  };

  onMount(() => {
    detectIsADesktop();
    window.onresize = () => setTimeout(() => detectIsADesktop(), 100);
    loading = true;
    Promise.all([
      getCountries({ propertyId: propertyInformation.id }).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      ),
    ]).then(([countriesData]) => {
      listCountries = countriesData;
      errorsDeclined = request.errorsDeclined;
      loading = false;
      setTimeout(
        () =>
          document.querySelector("#continue").addEventListener("click", () => {
            if (!request.nationality.id) request.nationality = undefined;
            $dataForm.touched = true;
          }),
        200
      );
    });
  });

  const saveData = () => {
    disabledButton = true;
    reValidateRequestById({
      propertyId: propertyInformation.id,
      requestId: request.id,
      body: request,
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      )
      .then((response) => {
        window.location.href = response.urlRedirect;
      })
      .catch((err) => {
        console.error(err);
        disabledButton = false;
      });
  };
</script>

{#if loading}
  <ReloadingComponent bind:managementColors={guestAppSettings} />
{:else}
  <section class="container-fluid cont__card__date">
    <div class="cont__back__err">
      <span class="text__warning">{$_("Recheck the following data")} </span>
      <i class="material-icons icon__warning">warning</i>
    </div>
  </section>
  <section class="container">
    <div class="my-container-check-in">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              {#if request.errorsDeclined.find((e) => e == "documentBack") || request.errorsDeclined.find((e) => e == "documentFront")}
                <div class="col-12">
                  <DocumentsComponent
                    bind:request
                    bind:propertyInformation
                    on:onChange={dectectedImgChange}
                  />
                </div>
              {/if}
              <form class={isADesktop ? "row" : ""} id="dataForm" use:dataForm>
                {#if request.errorsDeclined.find((e) => e == "firstName")}
                  <div class="mb-4 {isADesktop ? 'col-6' : ''}">
                    <label
                      for="firstName"
                      class="form-label {!$dataForm.firstName?.valid &&
                      $dataForm.firstName?._touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("First name")}
                    </label>
                    <input
                      use:validators={[required, maxLength(40)]}
                      type="text"
                      class="form-control"
                      id="firstName"
                      name="firstName"
                      autocomplete="new-password"
                      maxlength="40"
                      placeholder={$_("Enter your first name")}
                      bind:value={request.firstName}
                      on:input={(ev) => limitField(ev, /[^a-zÀ-ÿñ\s]/gi)}
                      on:change={detectedChangeInput("firstName")}
                    />
                    <Hint
                      class="help-block help-block-left field-invalid"
                      for="firstName"
                      on="required"
                    >
                      {$_("This field is required")}
                    </Hint>
                  </div>
                {/if}

                {#if request.errorsDeclined.find((e) => e == "lastName")}
                  <div class="mb-4 {isADesktop ? 'col-6' : ''}">
                    <label
                      for="lastName"
                      class="form-label {!$dataForm.lastName?.valid &&
                      $dataForm.lastName?._touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Last name")}
                    </label>
                    <input
                      use:validators={[required, maxLength(100)]}
                      type="text"
                      class="form-control"
                      id="lastName"
                      name="lastName"
                      autocomplete="new-password"
                      maxlength="40"
                      placeholder={$_("Enter your last name")}
                      on:change={detectedChangeInput("lastName")}
                      on:input={(ev) => limitField(ev, /[^a-zÀ-ÿñ\s]/gi)}
                      bind:value={request.lastName}
                    />
                    <Hint
                      class="help-block help-block-left field-invalid"
                      for="lastName"
                      on="required"
                    >
                      {$_("This field is required")}
                    </Hint>
                  </div>
                {/if}

                {#if request.errorsDeclined.find((e) => e == "birthDate")}
                  <div class="mb-4 {isADesktop ? 'col-6' : ''}">
                    <label
                      for="birthday"
                      class="form-label {!$dataForm.birthday?.valid &&
                      $dataForm.birthday?._touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Date of birth")}
                    </label>
                    <input
                      use:validators={[required]}
                      type="date"
                      class="form-control"
                      id="birthday"
                      name="birthday"
                      autocomplete="new-password"
                      placeholder={$_("Ex") + ": 05/12/86"}
                      min="1910-01-01"
                      max={(() => {
                        const maxBirthdayDate = new Date();
                        maxBirthdayDate.setFullYear(
                          maxBirthdayDate.getFullYear() - 18
                        );
                        maxBirthdayDate.setMonth(11, 31);
                        return new Date(maxBirthdayDate)
                          .toISOString()
                          .split("T")[0];
                      })()}
                      bind:value={request.birthday}
                      on:change={detectedChangeInput("birthDate")}
                    />
                    <Hint
                      class="help-block help-block-left field-invalid"
                      for="birthday"
                      on="required"
                    >
                      {$_("This field is required")}
                    </Hint>
                  </div>
                {/if}

                {#if request.errorsDeclined.find((e) => e == "nationality")}
                  <div class="mb-4 {isADesktop ? 'col-6' : ''}">
                    <label
                      for="nationality"
                      class="form-label {!request.nationality
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Nationality")}
                    </label>
                    <SelectSearchable
                      bind:list={listCountries}
                      name="nationality"
                      id="nationality"
                      placeholder={$_("Select nationality")}
                      required={true}
                      pattern={/[^a-zÀ-ÿñ\s]/gi}
                      bind:defaultValue={request.nationality}
                      on:setValue={(event) => {
                        if (request.nationality.id != event.detail.id) {
                          detectedChangeInput("nationality")();
                        }
                        if (
                          event.detail?.id != propertyInformation.country.id
                        ) {
                          documentDefaultValue = listDocumentsTypes.map(
                            (lst) => ({ ...lst, id: lst.codename })
                          )[0];
                          request.documentType = listDocumentsTypes[0].codename;
                          showDocumentType = false;
                          setTimeout(() => (showDocumentType = true), 5);
                        }
                        request.nationality = event.detail;
                      }}
                    />
                  </div>
                {/if}

                {#if request.errorsDeclined.find((e) => e == "documentNumber")}
                  <div class="mb-4">
                    <div class="row">
                      <div class="col-6">
                        <label for="nationality" class="form-label">
                          {$_("Document type")}
                        </label>
                        {#if showDocumentType}
                          <SelectSearchable
                            list={(!request.nationality ||
                            request.nationality.id !=
                              propertyInformation.country.id
                              ? [listDocumentsTypes[0]]
                              : listDocumentsTypes
                            ).map((lst) => ({ ...lst, id: lst.codename }))}
                            name="documentType"
                            id="documentType"
                            placeholder={$_("Select type of document")}
                            blockSearch={true}
                            bind:defaultValue={documentDefaultValue}
                            on:setValue={(event) =>
                              (request.documentType = event.detail?.codename)}
                          />
                        {:else}
                          <select class="form-select">
                            <option value={listDocumentsTypes[0].codename}>
                              {listDocumentsTypes[0].name}
                            </option>
                          </select>
                        {/if}
                      </div>
                      <div class="col-6">
                        <label
                          for="nationality"
                          class="form-label {!$dataForm.documentNumber?.valid &&
                          $dataForm.documentNumber?._touched
                            ? 'field-invalid'
                            : ''}"
                        >
                          {$_("Document number")}
                        </label>
                        <input
                          use:validators={[required, maxLength(100)]}
                          name="documentNumber"
                          id="documentNumber"
                          type="text"
                          class="form-control"
                          autocomplete="new-password"
                          maxlength="40"
                          placeholder={$_("Enter your document number")}
                          on:input={(ev) => limitField(ev, /[^a-z0-9-]/gi)}
                          bind:value={request.documentNumber}
                          on:change={detectedChangeInput("documentNumber")}
                        />
                        <Hint
                          class="help-block help-block-left field-invalid"
                          for="documentNumber"
                          on="required"
                        >
                          {$_("This field is required")}
                        </Hint>
                      </div>
                    </div>
                  </div>
                {/if}

                {#if request.errorsDeclined.find((e) => e == "documentExpiration")}
                  <div class="mb-4 {isADesktop ? 'col-6' : ''}">
                    <label
                      for="documentExpirationDate"
                      class="form-label {!$dataForm.documentExpirationDate
                        ?.valid && $dataForm.documentExpirationDate?._touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Document expiration date")}
                    </label>
                    <input
                      use:validators={[required]}
                      type="date"
                      class="form-control"
                      id="documentExpirationDate"
                      name="documentExpirationDate"
                      autocomplete="new-password"
                      placeholder={$_("Ex") + ": 12/2030"}
                      min={new Date().toISOString().split("T")[0]}
                      bind:value={request.documentExpirationDate}
                      on:change={detectedChangeInput("documentExpiration")}
                    />
                    <Hint
                      class="help-block help-block-left field-invalid"
                      for="documentExpirationDate"
                      on="required"
                    >
                      {$_("This field is required")}
                    </Hint>
                  </div>
                {/if}

                {#if request.errorsDeclined.find((e) => e == "address")}
                  <div class="mb-4">
                    <label
                      for="address"
                      class="form-label {!$dataForm.address?.valid &&
                      $dataForm.address?._touched
                        ? 'field-invalid'
                        : ''}"
                    >
                      {$_("Residence address")}
                    </label>
                    <input
                      use:validators={[required, maxLength(100)]}
                      type="text"
                      class="form-control"
                      id="address"
                      name="address"
                      autocomplete="new-password"
                      placeholder={$_("Enter your location of residence")}
                      on:input={(ev) => limitField(ev, /[^a-z0-9À-ÿñ.:,°\s]/gi)}
                      maxlength="100"
                      bind:value={request.address}
                      on:change={detectedChangeInput("address")}
                    />
                    <div style="display: flex;">
                      <Hint
                        class="help-block help-block-left field-invalid"
                        for="address"
                        on="required">{$_("This field is required")}</Hint
                      >
                      <span class="help-block help-block-right">
                        {request.address.length}/100
                      </span>
                    </div>
                  </div>
                {/if}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="my-footer-button">
    <div class="row">
      <div id="continue" class="col-12 offset-md-9 col-md-3">
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block"
          disabled={!$dataForm.valid ||
            (request.errorNationality ? !request.nationality : false) ||
            disabledButton ||
            errorsDeclined.length > 0}
          on:click={saveData}
        >
          {$_("Continue")}
        </button>
      </div>
    </div>
  </div>
{/if}

<style>
  .my-footer-button {
    z-index: 2;
  }

  .container-fluid {
    padding: 33px 0px 33px 0px;
    display: flex;
    margin-top: -3px;
  }

  .cont__back__err {
    text-align: center;
    width: 100%;
  }

  .icon__warning {
    color: #ffa64d;
    font-size: 60px;
    margin-top: 23px;
    background-color: #ffeddb;
    border-radius: 50%;
    padding: 12px;
  }

  .text__warning {
    display: block;
    font-size: 24px;
    font-weight: 700;
    max-width: 45%;
    text-align: center;
    margin: 0 auto;
  }

  @media (min-width: 20px) {
    .text__warning {
      max-width: 95%;
    }
  }

  @media (min-width: 633px) {
    .text__warning {
      max-width: 65%;
    }
  }

  @media (min-width: 1200px) {
    .text__warning {
      max-width: 45%;
    }
    .card{
      margin-bottom: 250px;
    }
  }

  @media (min-width: 1600px) {
    .text__warning {
      max-width: 36%;
    }
  }

  @media (min-width: 1000px) {
        .card{
      margin-bottom: 250px;
    }
  }
</style>
