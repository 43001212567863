<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import ReloadingComponent from "../components/reloading-component.svelte";
  import { goBack, headerInformation } from "../header/service";

  export let fromHome = true;
  let loading = true;

  onMount(() => {
    initComponent();
  });

  const initComponent = () => {
    loading = true;
    loading = false;
  };
</script>

{#if loading}
  <ReloadingComponent />
{:else}
  <section class="container">
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">Ups, pagina no encontrada.</h5>
        <p class="card-text">
          Lo que buscas no esta por acá
        </p>
      </div>
    </div>
  </section>
{/if}
