<script>
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import {
    headerInformation,
    goBack,
    backInCurrentPage,
    onClickBackEvent,
    showMenu,
  } from "../../header/service";
  import {
    useForm,
    required,
    validators,
    email,
    maxLength,
    Hint,
    HintGroup,
  } from "svelte-use-form";

  import { WorkflowServices } from "../service";

  import ReloadingComponent from "../../components/reloading-component.svelte";
  import { formDataPost } from "../../http-service";

  export let propertyInformation;
  export let guestAppFeatures;
  export let guestAppSettings;
  export let params;
  export let queryParams;

  const dataForm = useForm();
  let errorNotFound = false;
  let surveyCompleted = false;
  let loading = true;
  let disabledButton = false;
  let formDetail = null;

  $: {
    disabledButton = !$dataForm.valid;
  }

  onMount(() => {
    locale.subscribe((lang) => initComponent());
  });

  const initComponent = () => {
    loading = true;
    errorNotFound = false;
    surveyCompleted = false;
    showMenu.set(false);
    goBack.set(null);
    headerInformation.set({
      title: propertyInformation.name,
      logo: propertyInformation.logo,
    });

    WorkflowServices.getWorkflowById({
      propertyId: propertyInformation.id,
      workflowId: $params.workflowId,
      messageId: $queryParams.messageId || null,
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response),
      )
      .then((surveyInfo) => {
        formDetail = surveyInfo;
        if (formDetail.status != "active") {
          surveyCompleted = true;
        } else {
          WorkflowServices.markOpenMessageByWorkflowId({
            propertyId: propertyInformation.id,
            workflowId: $params.workflowId,
            messageId: $queryParams.messageId || null,
            body: {
              channel: $queryParams.channel,
            },
          });
        }
        loading = false;
      })
      .catch((err) => {
        console.error(err);
        loading = false;
        errorNotFound = true;
      });
  };

  const onKeyPress = (event) => {
    // if (event.keyCode == 13 && !event.shiftKey) {
    //   event.preventDefault();
    // }
  };

  const onKeyUp = (event) => {
    event.target.style.height = "0";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  const sendSurvey = (event) => {
    disabledButton = true;
    WorkflowServices.sendMessageByWorkflowId({
      propertyId: propertyInformation.id,
      workflowId: $params.workflowId,
      messageId: $queryParams.messageId || null,
      channel: $queryParams.channel,
      body: formDetail.fields,
    })
      .then((response) => (response.ok ? response : Promise.reject(response)))
      .then(() => {
        surveyCompleted = true;
      })
      .catch((err) => {
        console.error(err);
        disabledButton = false;
        alert("ocurrio algun error inesperado. vuelva a intentar");
      });
  };

  const selectMultipleOptions = (i) => (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      // Si el checkbox está marcado y el valor no existe, agregarlo
      if (!formDetail.fields[i].value.includes(value)) {
        formDetail.fields[i].value.push(value);
        formDetail.fields[i].options[
          formDetail.fields[i].options.findIndex((v) => v.prompt == value)
        ].active = true;
      }
    } else {
      // Si el checkbox está desmarcado, eliminar el valor si existe
      const index = formDetail.fields[i].value.findIndex((v) => v === value);
      if (index !== -1) {
        formDetail.fields[i].value.splice(index, 1);
        formDetail.fields[i].options[
          formDetail.fields[i].options.findIndex((v) => v.prompt == value)
        ].active = false;
      }
    }
  };
</script>

{#if loading}
  <ReloadingComponent bind:managementColors={guestAppSettings} />
{:else if surveyCompleted}
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 91vh;"
  >
    <div class="text-center">
      <div class="col-12 style__flex">
        <div class="back__check">
        <i class="material-icons icon__check">check_circle</i>
      </div>
    </div>
      <h2 class="mt-3 title__check">
        {$_("Thanks for your time!")}
      </h2>
      <p  class="subtitle__check">
        {$_("Your feedback helps us to improve our service")}
      </p>
    </div>
    <div class="footer__white">
      <span class="txt__footer">Powered by</span>
      <img src="/images/gh-logo-white.png" style="width: 83px;"
      alt="">
    </div>
  </div>
{:else if errorNotFound}
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 91vh;"
  >
    <div class="text-center">
      <div class="col-12 style__flex">
      <div class="back__error">
      
      <i class="material-icons icon__err">cancel</i>
    </div>
  </div>
      <h2 class="mt-3 title__err">
        {$_("Sorry something happened")}
      </h2>
      <div style="padding-top: 24px;">
        <button type="button"
        class="btn btn-primary btn-lg btn__err pointer"
         on:click={() => (errorNotFound = false)}
          >{$_("Back to the survey")}</button
        >
      </div>
    </div>
    <div class="footer__white">
      <span class="txt__footer">Powered by</span>
      <img src="/images/gh-logo-white.png" style="width: 83px;"
      alt="">
    </div>
  </div>
{:else}
  <div class="card-header container {!propertyInformation.cover.url ? "nologo-cover" : "logo-cover"}" >
    <div>
      {#if propertyInformation.cover.url}
      <img
        class="w-100 cover__img"
        src={propertyInformation.cover.url}
        alt="{propertyInformation.name} cover"
      />
      {/if}
      <div class="div__logo">
        <img
          class="logo__img"
          src={propertyInformation.logo}
          alt="{propertyInformation.name} logo"
        />
      </div>
    </div>
  </div>

  <section class="container">
    <div class="my-container-check-in">
      <div class="col-12">
        <div class="back__ground">
          <div class="card-body">
            <form class="row" use:dataForm>
              <h1 class="fw-bold fnt_20">{formDetail.title}</h1>
              {#if formDetail.description && formDetail.description.length > 2}
                <p class="fnt_14">{formDetail.description}</p>
              {/if}

              <!-- Contenido -->
              {#each formDetail.fields as field, i (i)}
                <div class="col-12">
                  {#if field.type == "text"}
                    <div class="mb-12 col-12 col-sm-12 col-lg-12 padd__top32">
                      <label
                        for="field{i}"
                        class="fw-bold fnt_14 text__labels form-label label__data {!$dataForm[
                          'field' + i
                        ]?.valid &&
                        $dataForm['field' + i]?._touched &&
                        field.required
                          ? 'field-invalid'
                          : ''}"
                      >
                        {field.prompt}
                      </label>

                      <input
                        use:validators={field.required
                          ? [required, maxLength(500)]
                          : []}
                        type="text"
                        class="form-control"
                        id="field{i}"
                        name="field{i}"
                        autocomplete="off"
                        maxlength="500"
                        placeholder={$_(field.placeholder)}
                        required={field.required}
                        bind:value={field.value}
                      />
                      <div class="d-flex justify-content-between">
                        <small class="text-muted fnt_12">
                          {#if field.required}
                            {#if !$dataForm["field" + i]?.touched || ($dataForm["field" + i]?.touched && $dataForm["field" + i].valid)}
                              {$_("Required")}
                            {/if}
                            <HintGroup for="field{i}">
                              <Hint
                                class="help-block help-block-left field-invalid"
                                on="required"
                              >
                                {$_("This field is required")}
                              </Hint>
                              <Hint
                                class="help-block help-block-left field-invalid"
                                on="maxLength"
                                hideWhenRequired
                                let:value
                              >
                                {$_("Maximo [X] caracteres").replace(
                                  "[X]",
                                  value,
                                )}
                              </Hint>
                            </HintGroup>
                          {:else}
                            {$_("Optional")}
                          {/if}
                        </small>
                        <small class="text-muted fnt_12" id="characterCount"
                          >{field.value.length}/500</small
                        >
                      </div>
                    </div>
                  {:else if field.type == "email"}
                    <div class="mb-12 col-12 col-sm-12 col-lg-12 padd__top32">
                      <label
                        for="field{i}"
                        class="fw-bold fnt_14 text__labels form-label label__data {!$dataForm[
                          'field' + i
                        ]?.valid &&
                        $dataForm['field' + i]?._touched &&
                        field.required
                          ? 'field-invalid'
                          : ''}"
                      >
                        {field.prompt}
                      </label>

                      <input
                        use:validators={field.required ? [required, email] : []}
                        type="email"
                        class="form-control"
                        id="field{i}"
                        name="field{i}"
                        autocomplete="off"
                        placeholder={$_(field.placeholder)}
                        required={field.required}
                        bind:value={field.value}
                      />
                      <div class="d-flex justify-content-between">
                        <small class="text-muted fnt_12">
                          {#if field.required}
                            {#if !$dataForm["field" + i]?.touched || ($dataForm["field" + i]?.touched && $dataForm["field" + i].valid)}
                              {$_("Required")}
                            {/if}
                            <HintGroup for="field{i}">
                              <Hint
                                class="help-block help-block-left field-invalid"
                                on="required"
                              >
                                {$_("This field is required")}
                              </Hint>
                              <Hint
                                class="help-block help-block-left field-invalid"
                                on="email"
                                hideWhenRequired
                                let:value
                              >
                                {$_("Email is not valid")}
                              </Hint>
                            </HintGroup>
                          {:else}
                            {$_("Optional")}
                          {/if}
                        </small>
                      </div>
                    </div>
                  {:else if field.type == "textarea"}
                    <div class="mb-12 col-12 col-sm-12 col-lg-12 padd__top32">
                      <label
                        for="field{i}"
                        class="text__labels fw-bold fnt_14 form-label label__data {!$dataForm[
                          'field' + i
                        ]?.valid &&
                        $dataForm['field' + i]?._touched &&
                        field.required
                          ? 'field-invalid'
                          : ''}"
                      >
                        {field.prompt}
                      </label>

                      <textarea
                        use:validators={field.required
                          ? [required, maxLength(500)]
                          : []}
                        class="form-control back__ground"
                        id="field{i}"
                        name="field{i}"
                        autocomplete="off"
                        on:keyup={onKeyUp}
                        on:keypress={onKeyPress}
                        maxlength="500"
                        placeholder={$_(field.placeholder)}
                        required={field.required}
                        bind:value={field.value}
                      ></textarea>
                      <div class="d-flex justify-content-between">
                        <small class="text-muted fnt__12 fnt_12">
                          {#if field.required}
                            {#if !$dataForm["field" + i]?.touched || ($dataForm["field" + i]?.touched && $dataForm["field" + i].valid)}
                              {$_("Required")}
                            {/if}
                            <HintGroup for="field{i}">
                              <Hint
                                class="help-block help-block-left field-invalid"
                                on="required"
                              >
                                {$_("This field is required")}
                              </Hint>
                              <Hint
                                class="help-block help-block-left field-invalid"
                                on="maxLength"
                                hideWhenRequired
                                let:value
                              >
                                {$_("Maximo [X] caracteres").replace(
                                  "[X]",
                                  value,
                                )}
                              </Hint>
                            </HintGroup>
                          {:else}
                            {$_("Optional")}
                          {/if}
                        </small>
                        <small class="text-muted fnt_12" id="characterCount"
                          >{field.value.length}/500</small
                        >
                      </div>
                    </div>
                  {:else if field.type == "presentation"}
                    <div class="mb-12 col-12 col-sm-12 col-lg-12 padd__top32">
                      <!-- svelte-ignore a11y-label-has-associated-control -->
                      <label
                        for="field{i}"
                        class="text__labels fw-bold fnt_14 form-label label__data"
                      >
                        {field.prompt}
                      </label>
                      <div class="div__stars">
                        {#each field.options as option}
                          <button
                            class="star-button"
                            on:click|preventDefault={() =>
                              (field.value = option.prompt)}
                          >
                            <i
                              class=" material-icons {field.value &&
                              parseInt(field.value) >= parseInt(option.prompt)
                                ? 'star__yellow'
                                : ''}">star</i
                            >
                          </button>
                        {/each}
                      </div>
                      <div>
                        <span class="fw-bold fnt_14">{field.min.prompt}</span>
                        <span class="float-end fw-bold fnt_14"
                          >{field.max.prompt}</span
                        >
                        {#if field.required}
                        <small class="text-muted fnt__12 fnt_12" style="display: block;">
                          {$_("Required")}
                        </small>
                        {:else}
                        <small class="text-muted fnt__12 fnt_12" style="display: block;">
                          {$_("Optional")}
                        </small>
                        {/if}
                      </div>
                    </div>
                  {:else if field.type == "list"}
                    <div class="mb-12 col-12 col-sm-12 col-lg-12 padd__top32">
                      <div class="padd__btm8">
                        <span class="fw-bold fnt_14">
                          {field.prompt}
                        </span>
                      </div>

                      {#each field.options as option, i2 (option.prompt)}
                        <div class="form-check">
                          <label
                            class="form-check-label"
                            for="flexCheckDefault{i}{i2}"
                            class:border__active={option.active}
                          >
                            <input
                              class="form-check-input input__back"
                              style="margin-right: 4px;"
                              type="checkbox"
                              id="flexCheckDefault{i}{i2}"
                              name="option{i}"
                              value={option.prompt}
                              use:validators={field.required ? [required] : []}
                              on:change={selectMultipleOptions(i)}
                            />
                            {option.prompt}
                          </label>
                        </div>
                      {/each}

                      {#if field.required}
                        <HintGroup for="option{i}">
                          <Hint
                            class="help-block help-block-left field-invalid"
                            on="required"
                          >
                            {$_("This field is required")}
                          </Hint>
                          <Hint
                            class="help-block help-block-left field-invalid"
                            on="maxLength"
                            hideWhenRequired
                            let:value
                          >
                            {$_("Maximo [X] caracteres").replace("[X]", value)}
                          </Hint>
                        </HintGroup>
                      {/if}
                    </div>
                  {:else if field.type == "radiogroup"}
                    <div class="mb-12 col-12 col-sm-12 col-lg-12 padd__top32">
                      <div class="padd__btm8">
                        <span class="fw-bold fnt_14">
                          {field.prompt}
                        </span>
                      </div>

                      {#each field.options as option, i2 (option.prompt)}
                        <div class="form-check">
                          <label
                            for="flexRadioDefault1{i}{i2}"
                            class="form-check-label"
                            class:border__active={option.prompt == field.value}
                          >
                            <input
                              class="form-check-input input__back input__radio"
                              type="radio"
                              id="flexRadioDefault1{i}{i2}"
                              name="option{i}"
                              value={option.prompt}
                              use:validators={field.required ? [required] : []}
                              on:change={(e) => (field.value = e.target.value)}
                            />
                            {option.prompt}
                          </label>
                        </div>
                      {/each}

                      {#if field.required}
                        <HintGroup for="option{i}">
                          <Hint
                            class="help-block help-block-left field-invalid"
                            on="required"
                          >
                            {$_("This field is required")}
                          </Hint>
                          <Hint
                            class="help-block help-block-left field-invalid"
                            on="maxLength"
                            hideWhenRequired
                            let:value
                          >
                            {$_("Maximo [X] caracteres").replace("[X]", value)}
                          </Hint>
                        </HintGroup>
                      {/if}
                    </div>
                  {/if}
                </div>
              {/each}
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="my-footer-button">
    <div class="row">
      <div id="continue" class="col-12 offset-md-9 col-md-3">
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block"
          disabled={!$dataForm.valid ||
            disabledButton ||
            formDetail.fields.some((e) =>
              e.type == "presentation" ? !e.value && e.required == true : false,
            )}
          on:click={sendSurvey}
          style="background-color: {guestAppSettings.color};"
        >
          {$_("Continue")}
        </button>
      </div>
    </div>
  </div>
{/if}

<style>
  .my-footer-button {
    z-index: 2;
  }

  .star-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .card-header {
    margin-bottom: -136px;
  }

  .cover__img {
    border-radius: 16px;
  }

  .logo-position {
    text-align: center;
    position: relative;
    top: -157px;
  }

  p {
    margin-bottom: 0px;
  }

  .padd__top32 {
    padding-top: 32px;
  }

  .back__ground {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-color: var(--color-letter);
  }

  .text__labels {
    text-transform: capitalize;
  }

  .div__stars {
    display: flex;
    justify-content: space-between;
    padding: 8px 20px 8px 20px;
  }

  .star__yellow {
    color: #febc2c;
  }

  .padd__btm8 {
    padding-bottom: 8px;
  }

  .form-check {
    margin-bottom: 8px;
  }

  .form-check .form-check-label {
    display: block !important;
    padding: 16px !important;
    border: 1px solid var(--button-circle);
    border-radius: 8px;
  }

  .form-check .form-check-label:checked {
    border: 1px solid var(--dynamic-color);
  }

  .fnt_14 {
    font-size: 14px;
  }

  .fnt_20 {
    font-size: 20px;
  }

  .fnt_12 {
    font-size: 12px !important;
  }
  .input__radio {
    margin-right: 4px;
    position: relative;
    bottom: 3px;
  }

  .input__back {
    background-color: var(--background-color);
  }

  .input__back:checked {
    background-color: var(--dynamic-color);
  }

  .form-check .form-check-input {
    border-color: var(--circle-background-color) !important;
    margin-left: 0px;
  }

  .form-check .form-check-input:checked {
    border-color: var(--dynamic-color) !important;
    margin-left: 0px;
  }

  .border__active {
    border-color: var(--dynamic-color) !important;
  }

  textarea {
    min-height: 37px !important;
    height: 37px;
  }

  .btn__err{
    padding: 8px 16px;
    background-color: var(--circle-background-color) !important;
    color: var(--btn-primary-color) !important;
    font-size: 16px !important;
     font-weight: 600 !important;
  }

  .btn__err:hover{
    box-shadow: 0px 4px 16px 0px rgba(var(--shadows-button), .5);
    
  }

  .style__flex{
    display: flex;
    justify-content: center;
}

.back__check{
  background-color: rgba(var(--background-check), .2);
    border-radius: 200px;
    padding: 16px;
}

.icon__check{
  font-size: 6rem  !important;
    color: #1AB394;
    position: relative;
    top: 3px;
}

  .back__error{
    background-color: rgba(var(--background-err), .2);
    border-radius: 200px;
    padding: 16px;

  }

  .icon__err{
    font-size: 6rem 
 !important;
    color: #FF4855;
    position: relative;
    top: 3px;
  }

  .title__err{
    font-size: 2.25rem;
    font-weight: 700 !important;
    color: var(--circle-background-color);
  }

  .title__check{
    font-size: 3rem;
    color: var(--circle-background-color);
    font-weight: 700 !important;
  }

  .subtitle__check{
    font-size: 1rem;
    color: var(--color-title);
    font-weight: 400;
  }

  .footer__white{
    position: absolute;
    bottom: 22px;
  }

  .txt__footer{
    font-size: 0.625rem;
    margin-right: 0.313rem;
    color: var(--color-title);
  }
  @media (min-width: 20px) {
    .logo-cover {
      max-width: 100%;
      margin-bottom: -71px;
      margin-top: -12px;
    }

    .nologo-cover {
      max-width: 100%;
      margin-bottom: -71px;
      margin-top: 87px;
    }
    .cover__img {
      border-radius: 0px;
    }

    .logo__img {
      width: 102px;
      height: 102px;
      border-radius: 8px;
    }

    .div__logo {
      position: relative;
      text-align: center;
      top: -80px;
    }
  }

  @media (min-width: 633px) {
    .logo-cover {
      max-width: 65%;
      margin-bottom: -113px;
      margin-top: 141px;
    }

    .nologo-cover {
      max-width: 65%;
      margin-bottom: -113px;
      margin-top: 0px;
    }

    .cover__img {
      border-radius: 16px;
    }
    

    .logo__img {
      width: 148px;
      height: 148px;
      border-radius: 8px;
    }
    .div__logo {
      position: relative;
      text-align: center;
      top: -125px;
    }
  }

  @media (min-width: 1200px) {
    .logo-cover {
      max-width: 45%;
    }

    .nologo-cover {
      max-width: 45%;
      margin-top: 166px;
    }
    .logo__img {
      width: 190px;
      height: 190px;
    }
    .div__logo {
      position: relative;
      text-align: center;
      top: -152px;
    }
  }

  @media (min-width: 1600px) {
    .logo-cover {
      max-width: 36%;
    }

    .nologo-cover {
      max-width: 36%;
      margin-top: 184px;
    }
    .logo__img {
      width: 204px;
      height: 204px;
    }
    .div__logo {
      position: relative;
      text-align: center;
      top: -172px;
    }
  }

  @media (min-width: 20px) and (max-width: 426px) {
    .div__stars {
      display: block;
      padding: 8px 0px 8px 0px;
    }
  }
</style>
