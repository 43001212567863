<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import TermsDemo from "../../components/terms-demo.svelte";
  import { getAllTermsAndConditions } from "../service";
  export let propertyInformation;
  export let isADesktop;
  export let reservation;
  const dispatch = createEventDispatcher();
  let termBody;
  let termsList = [];
  let termsAccepted = [];
  let modalOpen = false;
  let showAlert = false;
  let loading = true;

  onMount(() => {
    loading = true;
    Promise.all([
      getAllTermsAndConditions({ propertyId: propertyInformation.id }).then(
        (response) => (response.ok ? response.json() : Promise.reject(response))
      ),
    ]).then(([terms]) => {
      termsList = terms;
      loading = false;
    });
  });

  const handleAccepted = (event) => {
    event.target.checked === true
      ? termsAccepted.push(true)
      : termsAccepted.pop();
    termsAccepted = termsAccepted;
  };

  const nextStage = () => {
    if (termsAccepted.length === termsList.length) {
      reservation.terms = termsList.map((t) => t.id);
      dispatch("nextStage", "nextStage");
    }
  };
</script>

<section class="container {modalOpen ? 'hidden' : ''}">
  <div class="my-container-check-in {isADesktop ? 'check-adjust' : ''}">
    <div class="row">
      <div class="col-12">
        <h1 class="card-title">{$_("Terms and Conditions")}</h1>
        <div class="card">
          <div class="card-body">
            {#each termsList as term}
              <div style="margin-bottom: 19px;" class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="true"
                  on:click={handleAccepted}
                  id="acceptTermsCondition{term.id}"
                />
                <label
                  class="form-check-label"
                  style="margin-left: 8px;"
                  for="acceptTermsCondition{term.id}"
                >
                  {term.title.includes("Políticas")
                    ? $_("I accept the F")
                    : $_("I accept the M")}
                  <a
                  class="color__terms"
                    href="#{term.id}"
                    target="_blank"
                    on:click|preventDefault={() => {
                      modalOpen = true;
                      termBody = term
                    }}>{term.title}</a
                  >
                </label>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
    {#if termsAccepted.length !== termsList.length && showAlert}
      <div class="col-12">
        <div class="alert-condition">
          <i class="material-icons">warning</i>
          <span style="vertical-align: super;">{$_("Must accept terms")}</span>
        </div>
      </div>
    {/if}
  </div>
</section>

{#if !loading}
  <div class="my-footer-button {modalOpen ? 'hidden' : ''}">
    <div class="row">
      <div
        on:click|preventDefault={() =>
          (showAlert = termsAccepted.length !== termsList.length)}
        on:keyup
        class="col-12 offset-md-9 col-md-3"
        style="text-decoration: none"
        id="continue"
      >
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block"
          disabled={termsAccepted.length !== termsList.length}
          on:click={nextStage}
        >
          {$_("Continue")}
        </button>
      </div>
    </div>
  </div>
  <div class={!modalOpen ? "hidden" : "poss__head"}>
    <TermsDemo  bind:termBody on:closeTerms={() => (modalOpen = false)} />
  </div>
{/if}

<style>
  .check-adjust {
    margin: 80px auto 90px auto;
  }
  .alert-condition {
    padding: 16px;
    display: flex;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 8px;
    bottom: 17%;
    position: fixed;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .poss__head{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: var(--background-color);
  }
  @media only screen and (max-width: 556px) {
    .alert-condition {
      width: 90%;
    }
  }
  @media only screen and (max-width: 631px) {
    .alert-condition {
      width: 90%;
    }
  }
  .alert-condition i {
    color: #ffa64d;
    margin-right: 15px;
    margin-left: 5px;
  }
  .hidden {
    display: none;
  }
</style>
