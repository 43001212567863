import { environments } from "../assets/environments";
import { httpGet, httpPut } from "../http-service";
import { browserIdentify, user } from "../authService";

export const getRequests = input =>
    httpGet(
        `${environments.guestAppApi}/${input.propertyId}/my-requests?browserIdentify=${browserIdentify}${user ? `&confirmation=${user.reservationId}` : ``
        }`
    );

export const getRequestById = input =>
    httpGet(
        `${environments.guestAppApi}/${input.propertyId}/my-request/${input.requestId}?browserIdentify=${browserIdentify}${user ? `&confirmation=${user.reservationId}` : ``
        }`
    );

export const reValidateRequestById = input =>
    httpPut(
        `${environments.guestAppApi}/${input.propertyId}/my-request/${input.requestId}/validate?code=${user.code}browserIdentify=${browserIdentify}${user ? `&confirmation=${user.reservationId}` : ``
        }`,
        input.body
    );