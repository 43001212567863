import { writable } from "svelte/store";
import router from "page";

export let page;
export const activeRoute = writable("/");
export const params = writable({});
export const queryParams = writable({});

export const redirect = router.redirect;

import HomePage from "./home/page.svelte";
import FeaturePage from "./feature/page.svelte";
import PropertyInformationPage from "./property-information/page.svelte";
import EmailPreview from "./email-preview/email-preview.svelte";
import MyRequestsListPage from "./my-requests/list/page.svelte";
import MyRequestsDetailPage from "./my-requests/detail/page.svelte";

import CheckInPage from "./check-in/page.svelte";

import WorkflowSurveyPage from "./workflow/survey/page.svelte";

const routes = [
  { path: "/:propertyId", component: HomePage },
  { path: "/:propertyId/check-in", component: CheckInPage },
  { path: "/:propertyId/workflow/survey/:workflowId", component: WorkflowSurveyPage },
  {
    path: "/:propertyId/property-information",
    component: PropertyInformationPage,
  },
  { path: "/:propertyId/my-requests", component: MyRequestsListPage },
  {
    path: "/:propertyId/my-request/:requestId",
    component: MyRequestsDetailPage,
  },
  {
    path: "/:propertyId/email-preview/:type/:langCode",
    component: EmailPreview,
  },
  { path: "/:propertyId/:featureId", component: FeaturePage },

  { path: "**" },
];

routes.forEach((route) => {
  router(
    route.path,

    (ctx, next) => {
      activeRoute.set(ctx.routePath);
      params.set(ctx.params);
      queryParams.set(
        ctx.querystring
          ? ctx.querystring.split("&").reduce((r, c) => {
            r[c.split("=")[0]] = c.split("=")[1];
            return r;
          }, {})
          : {}
      );
      next();
    },

    () => {
      //if (route.auth && !user) {
      //  router.redirect("/login");
      //} else {
      page = route.component;
      //}
    }
  );
});

// Start the router
router.start();

export const routerStop = router.stop;