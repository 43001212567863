<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import ImageUpload from "../../components/image-upload.svelte";
  export let propertyInformation;
  export let reservation;
  export let guestAppSettings;
  const dispatch = createEventDispatcher();
  const mode = {
    passport: "passport",
    dni: "identity card",
    "licence-driver": "drive licence",
    "birth-certificate": "birth certificate",
  }[reservation.documentType];

  onMount(() => {});

  const nextStage = () => {
    dispatch("nextStage", "nextStage");
  };

  const loadPicture = (data, mode) => {
    const res = {
      id: new Date().getTime(),
      url: data.detail,
      loading: false,
    };
    if (mode == "back") reservation.documentImagenBack = res;
    else reservation.documentImagenFront = res;
  };
</script>

<section class="container">
  <div class="my-container-check-in">
    <div class="row">
      <div class="col-12">
        <div>
          {#if reservation.documentType == "passport"}
            <h1 class="card-title">{$_("Passport")}</h1>
            <p>{$_("Take a photo of your passport")}</p>
          {:else if reservation.documentType == "dni"}
            <h1 class="card-title">{$_("Identity card")}</h1>
            <p>{$_("Take a photo id msg")}</p>
          {:else if reservation.documentType == "licence-driver"}
            <h1 class="card-title">{$_("Drive Licence")}</h1>
            <p>{$_("Take a Take a photo of the drive licence")}</p>
          {:else if reservation.documentType == "birth-certificate"}
            <p>{$_("Take a Take a photo of the birth certificate")}</p>
          {/if}
        </div>
        <div class="card resize mode-card">
          <ImageUpload
            id="front"
            message="Photo of the front of the {mode}"
            on:setValue={(event) => loadPicture(event, "front")}
            bind:propertyInformation
            bind:guestAppSettings
            bind:data={reservation.documentImagenFront.url}
          />
        </div>

        {#if reservation.documentType != "passport"}
          <div class="card resize mode-card">
            <ImageUpload
              id="back"
              message="Photo of the back of the {mode}"
              on:setValue={(event) => loadPicture(event, "back")}
              bind:propertyInformation
              bind:guestAppSettings
              bind:data={reservation.documentImagenBack.url}
            />
          </div>
        {/if}
      </div>
    </div>
  </div>
</section>

<div class="my-footer-button">
  <div class="row">
    <div class="col-12 offset-md-9 col-md-3">
      <button
        type="button"
        class="btn btn-primary btn-lg btn-block"
        disabled={reservation.documentType != "passport"
          ? !reservation.documentImagenFront.url ||
            !reservation.documentImagenBack.url
          : !reservation.documentImagenFront.url}
        on:click={nextStage}
      >
        {$_("Continue")}
      </button>
    </div>
  </div>
</div>

<style>
  .resize {
    width: "100%";
    margin-bottom: 16px;
  }
</style>
