<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import { uploadImgs } from "../check-in/service";
  import ModalError from "./modal-error.svelte";
  import ReloadingComponent from "./reloading-component.svelte";
  const dispatch = createEventDispatcher();
  export let propertyInformation;
  let selectedCamera = 0;
  let camerasCount = 0;
  let alreadyClick = false;
  let video;
  let canvas;
  let switchCameraButtonElement;
  let screenHeight = window.screen.height;
  let screenWidth = window.screen.width;
  let deviceError = false;
  let firstSelected = false;
  let loading = false;
  let reloadComp;
  const ua = navigator.userAgent;
  onMount(() => initMedia());

  function getCameras() {
    return navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: false,
      })
      .then(() =>
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) =>
            devices.filter((device) => device.kind === "videoinput")
          )
      );
  }

  function switchCamera(cameras) {
    if (camerasCount === selectedCamera + 1) {
      selectedCamera = 0;
    } else {
      selectedCamera = selectedCamera + 1;
    }
    return Promise.resolve(cameras[selectedCamera]);
  }

  const initMedia = () => {
    video = document.querySelector("#video");
    canvas = document.querySelector("#canvas");
    getCameras()
      .then((devices) => {
        camerasCount = devices.length;
        devices.length > 1 &&
          setTimeout(() => {
            window.addEventListener("resize", () => {
              if (window.screen.height !== screenHeight) {
                screenHeight = window.screen.height;
                screenWidth = window.screen.width;
                streamMedia(devices[selectedCamera]);
              }
            });
            switchCameraButtonElement.addEventListener("click", () =>
              switchCamera(devices)
                .then((camera) => {
                  stopCamera();
                  return streamMedia(camera);
                })
                .catch(() =>
                  switchCamera(devices).then((camera) => streamMedia(camera))
                )
            ),
              2000;
          });

        streamMedia(devices[selectedCamera]);
      })
      .catch((err) => {
        console.error(err);
        deviceError = true;
        setTimeout(() => onClickClose(), 2000);
      });
  };

  const streamMedia = (device) => {
    const isAndroid =
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      );
    const isMac = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua);
    const resolution = () => {
      const vertical = { height: 340 * 3, width: 223 * 3 };
      const horizontal = { height: 223 * 3, width: 340 * 3 };
      if (isAndroid && device.label.includes("front")) {
        if (screenHeight < screenWidth) return vertical;
        else return { height: 340 * 2, width: 340 };
      } else return screenHeight < screenWidth ? horizontal : vertical;
    };

    return navigator.mediaDevices
      .getUserMedia({
        video: {
          ...resolution(),
          deviceId: device.deviceId,
          ...(!firstSelected && (isAndroid || isMac)
            ? { facingMode: { exact: "environment" } }
            : {}),
        },
        audio: false,
      })
      .then((stream) => {
        dispatch("errorPermission", false);
        video.srcObject = stream;
        video.click();
        video.play();
        firstSelected = true;
      })
      .catch((err) => {
        console.error("The camera failed because ==> :", err);
        dispatch("errorPermission", true);
        stopCamera();
        onClickClose();
        return Promise.reject(device);
      });
  };

  const onClickClose = () => {
    stopCamera();
    setTimeout(() => {
      video?.pause();
      stopCamera();
      dispatch("close", true);
    }, 150);
  };

  const onClickCapture = () => {
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    document.querySelector("#imagen").src = canvas.toDataURL("image/png");
    document.querySelector("#firstStage").style.display = "none";
    document.querySelector("#lastStage").style.display = "block";
  };

  const onClickTakeOtherPicture = () => {
    document.querySelector("#imagen").src = "";
    document.querySelector("#firstStage").style.display = "block";
    document.querySelector("#lastStage").style.display = "none";
  };
  const onClickReady = (ev) => {
    alreadyClick = true;
    loading = true;
    uploadImgs({
      data: document.querySelector("#imagen").src,
      propertyId: propertyInformation.id,
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      )
      .then((res) => {
        dispatch("ready", {
          id: new Date().getTime(),
          url: res.urlToPreview,
          loading: false,
        });
        loading = false;
        onClickClose();
        document.querySelector("#imagen").src = "";
        document.querySelector("#firstStage").style.display = "block";
        document.querySelector("#lastStage").style.display = "none";
      })
      .catch(() => (alreadyClick = false));
  };

  function stopCamera() {
    video?.srcObject?.getTracks().forEach((track) => track.stop());
  }
</script>

<div
  class="my-overlay-sidebar"
  style="opacity: 0.8; position: fixed;"
  id="myoverlayimagen"
/>
<div class="row modal-upload-imagen">
  <div
    id="firstStage"
    class="col-12"
    style="display: block; text-allign: center"
  >
    <span class="camera-view">
      <video class="video" id="video" autoplay playsinline>
        <track kind="captions" />
      </video>
      <button
        class="icono-round action-buttons"
        id="capture"
        disabled={deviceError}
        on:click={() => onClickCapture()}
      >
        <i class="material-icons">photo_camera</i>
      </button>
      {#if camerasCount > 1}
        <button
          bind:this={switchCameraButtonElement}
          class="icono-round action-buttons"
          id="swich"
        >
          <i class="material-icons">cameraswitch</i>
        </button>
      {/if}
    </span>
  </div>
  <div id="lastStage" class="col-12" style="display: none; text-allign: center">
    <span class="camera-view">
      {#if loading}
        <ReloadingComponent bind:this={reloadComp} />
      {/if}
      <img class="video" src="" alt="" id="imagen" />
      {#if !loading}
        <div class="btn-group" style="position: absolute; bottom: 9%; left: 42%">
          <button
            class="icono-round icono-correct"
            style="margin-right: 10px;"
            on:click={() => onClickTakeOtherPicture()}
            id="retake"
          >
            <i class="material-icons">restart_alt</i>
          </button>
          <button
            class="icono-round icono-correct"
            id="readyPhoto"
            on:click={() => onClickReady()}
            disabled={alreadyClick}
          >
            <i class="material-icons">check</i>
          </button>
        </div>
      {/if}
    </span>
  </div>
  <canvas id="canvas" style="display: none;" />
</div>
{#if deviceError}
  <ModalError message={$_("The camera failed to start, please try again.")} />
{/if}

<style>
  .modal-upload-imagen {
    position: fixed;
    top: 20%;
    z-index: 10;
    width: 100%;
    text-align: center;
  }
  .camera-view {
    max-width: 90%;
    max-height: 90%;
    position: fixed;
    transform: translate(-50%, 4%);
    left: 50%;
  }
  :global(.camera-view > .gueshub-services-reloading )  {
    background-color : var(--background-color) !important;
    border: 6px dashed #ffffff8a;
    border-radius: 5%;
  }
  .video {
    border-radius: 5%;
    border: 6px dashed #212121a0;
    background-color: white;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 6/4;
  }
  .action-buttons {
    position: initial;
    transform: translate(15%, -200%);
  }
  @media only screen and (min-height: 1280px) {
    .video {
      object-fit: cover;
    }
  }
  @media only screen and (max-height: 616px) {
    .modal-upload-imagen {
      top: 5%;
    }
  }
  @media only screen and (max-width: 632px) {
    .camera-view {
      width: 95%;
    }
  }
</style>
