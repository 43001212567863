<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import ReloadingComponent from "../components/reloading-component.svelte";
  import { goBack, headerInformation } from "../header/service";
  import { environments } from "../assets/environments";

  export let fromHome = true;
  export let propertyInformation;
  let loading = true;

  onMount(() => {
    initComponent();
  });

  const initComponent = () => {
    loading = true;
    if (!window.location.host.includes("localhost")) {
      window.location.href = environments.reservationsWeb
        .concat("/#/properties/")
        .concat(propertyInformation.id)
        .concat("/");
    }
    if (fromHome) {
      goBack.set(`/${propertyInformation.id}`);
      headerInformation.set(null);
      headerInformation.set({
        title: propertyInformation.name,
        logo: propertyInformation.logo,
      });
    }
    loading = false;
  };
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
</svelte:head>

{#if loading}
  <ReloadingComponent />
{:else}
  <section>
    <div class="card mb-3 property-information">
      <img
        src={propertyInformation.coverImageUrl}
        class="card-img-top property-information-cover"
        alt={propertyInformation.name}
      />

      <div class="card-body container">
        <h1 class="card-title">{propertyInformation.name}</h1>
        <p class="card-text">
          {@html propertyInformation.description}
        </p>
      </div>
    </div>
  </section>

  <section class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="card mb-3">
          <div class="row">
            <div class="col-5">
              <div class="card-body">
                <h6 class="card-subtitle mb-2 text-muted">CHECK-IN</h6>
                <h5 class="card-title">
                  {propertyInformation.hourCheckIn} hrs
                </h5>
              </div>
            </div>
            <div class="col-2">
              <i class="material-icons dinamyc-color">keyboard_arrow_right</i>
            </div>
            <div class="col-5">
              <div class="card-body">
                <h6 class="card-subtitle mb-2 text-muted">CHECK-OUT</h6>
                <h5 class="card-title">
                  {propertyInformation.hourCheckOut} hrs
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <ul class="list-group w-50">
      {#each propertyInformation.contactGuestInformation as contact}
        <li class="list-group-item align-items-center d-flex">
          <a class="btn btn-floating m-1 me-3" href="#!" role="button">
            {#if contact.icono}
              <i class="material-icons background-dinamyc-color"
                >{contact.icono}</i
              >
            {:else}
              <i class="{contact.class} background-dinamyc-color" />
            {/if}
          </a>
          {contact.url}
        </li>
      {/each}
    </ul>
  </section>

  <section class="container">
    <div class="card mb-3">
      <div class="row g-0">
        <div class="col-12 col-md-8">
          <!-- svelte-ignore a11y-missing-attribute -->
          <iframe
            aria-hidden="true"
            frameborder="0"
            tabindex="-1"
            style="z-index: -1; position: absolute; width: 100%; height: 100%; top: 0px; left: 0px; border: none;"
          />
        </div>
        <div class="col-12 col-md-4">
          <div class="card-body">
            <h5 class="card-title">{propertyInformation.name}</h5>
            <p class="card-text">
              {propertyInformation.address}
            </p>
            <p class="card-text">
              <small class="text-muted"
                >{propertyInformation.country.name}</small
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
{/if}

<style>
  .property-information > .property-information-cover {
    max-height: 300px;
  }
</style>
