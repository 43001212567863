import { isAuthenticated, token, user } from "./authService";

export const httpGet = (url, option) => {
  return fetch(url, {
    method: 'GET',
    headers: Object.assign({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'language': localStorage.getItem('lang') || 'en'
    }, isAuthenticated ? {
      'Authorization': `Bearer ${token}`
    } : {}),
    mode: 'cors'
  });
}

export const httpPost = (url, payload, option) => {
  return fetch(url, {
    method: 'POST',
    headers: Object.assign({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'language': localStorage.getItem('lang') || 'en'
    }, isAuthenticated ? {
      'Authorization': `Bearer ${token}`
    } : {}),
    mode: 'cors',
    body: JSON.stringify(payload)
  })
    .then(response => response.ok ? response : Promise.reject(response));
};

export const formDataPost = (url, formData) => {
  return fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      language: localStorage.getItem("lang") || "en",
      Accept: "*/*",
      ...(isAuthenticated
        ? {
          Authorization: `Bearer ${token}`,
        }
        : {})
    },
    mode: "cors"
  })
    .then((response) => (response.ok ? response : Promise.reject(response)));
};

export const httpPut = (url, payload, option) => {
  return fetch(url, {
    method: 'PUT',
    headers: Object.assign({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'language': localStorage.getItem('lang') || 'en'
    }, isAuthenticated ? {
      'Authorization': `Bearer ${token}`
    } : {}),
    mode: 'cors',
    body: JSON.stringify(payload)
  })
    .then(response => response.ok ? response : Promise.reject(response));
}

export const httpDelete = (url, payload, option) => {
  return fetch(url, {
    method: 'DELETE',
    headers: Object.assign({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'language': localStorage.getItem('lang') || 'en'
    }, isAuthenticated ? {
      'Authorization': `Bearer ${token}`
    } : {}),
    mode: 'cors',
    body: JSON.stringify(payload)
  })
    .then(response => response.ok ? response : Promise.reject(response));
}