<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  export let propertyInformation;
  export let guestAppSettings;
  export let reservation;
  export let isADesktop;
  export let currentStage;
  let companionSections = {
    adult: 0,
    child: 0,
    baby: 0,
  };
  const dispatch = createEventDispatcher();

  onMount(() => {
    if (reservation.companions.length > 0) {
      companionSections = {
        adult: reservation.companions.filter((a) => a.type == "adult").length,
        child: reservation.companions.filter((a) => a.type == "child").length,
        baby: reservation.companions.filter((a) => a.type == "baby").length,
      };
      reservation.companions = [];
    }
  });

  const nextStage = () => {
    let haveCompanion = false;
    Object.keys(companionSections).forEach((key) => {
      for (let i = 0; i < companionSections[key]; i++) {
        addNewCompanion(key);
        haveCompanion = true;
      }
    });
    dispatch("nextStage", "nextStage");
  };

  const addNewCompanion = (type) => {
    reservation.companions.push({
      index:
        reservation.companions.length === 0 ? 0 : reservation.companions.length,
      show: false,
      companionId:
        reservation.companions.filter((c) => c.type === type).length + 1,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      type: type,
    });
  };
</script>

<section class="container">
  <div class="my-container-check-in">
    <div class="row">
      <div class="col-12">
        <div>
          <h1 class="card-title">{$_("Additional guests")}</h1>
          <div style="display: flex; margin-bottom: 27px;">
            <div style="width: 100%;">
              <div class="selector-container">
                <div style="display: grid;">
                  <span style="font-size: 14px;">{$_("Adults")}</span>
                  <span class="description-text">
                    {$_("Over 18 years old")}
                  </span>
                </div>
                <div class="selector-buttons">
                  <button
                    on:click={() =>
                      (companionSections.adult = companionSections.adult - 1)}
                    disabled={companionSections.adult === 0}
                    class="button-no-stlye"
                  >
                    <i
                      class="material-icons icon-iphone-delete"
                      style="vertical-align: sub;"
                    >
                      remove
                    </i>
                  </button>
                  <span class="text-button-style">
                    {companionSections.adult}
                  </span>
                  <button
                    on:click={() =>
                      (companionSections.adult = companionSections.adult + 1)}
                    class="button-no-stlye"
                  >
                    <i
                      class="material-icons icon-iphone-plus"
                      style="vertical-align: sub;"
                    >
                      add
                    </i>
                  </button>
                </div>
              </div>

              <div class="selector-container">
                <div style="display: grid;">
                  <span style="font-size: 14px;">{$_("Children")}</span>
                  <span class="description-text">
                    {$_("Between 2 and 17 years old")}
                  </span>
                </div>
                <div class="selector-buttons">
                  <button
                    on:click={() =>
                      (companionSections.child = companionSections.child - 1)}
                    disabled={companionSections.child === 0}
                    class="button-no-stlye"
                  >
                    <i
                      class="material-icons icon-iphone-delete"
                      style="vertical-align: sub;"
                    >
                      remove
                    </i>
                  </button>
                  <span class="text-button-style">
                    {companionSections.child}
                  </span>
                  <button
                    on:click={() =>
                      (companionSections.child = companionSections.child + 1)}
                    class="button-no-stlye"
                  >
                    <i
                      class="material-icons icon-iphone-plus"
                      style="vertical-align: sub;"
                    >
                      add
                    </i>
                  </button>
                </div>
              </div>

              <div class="selector-container">
                <div style="display: grid;">
                  <span style="font-size: 14px;">{$_("Babies")}</span>
                  <span class="description-text">
                    {$_("Under 2 years of age")}
                  </span>
                </div>
                <div class="selector-buttons">
                  <button
                    on:click={() =>
                      (companionSections.baby = companionSections.baby - 1)}
                    disabled={companionSections.baby === 0}
                    class="button-no-stlye"
                  >
                    <i
                      class="material-icons icon-iphone-delete"
                      style="vertical-align: sub;"
                    >
                      remove
                    </i>
                  </button>
                  <span class="text-button-style">
                    {companionSections.baby}
                  </span>
                  <button
                    on:click={() =>
                      (companionSections.baby = companionSections.baby + 1)}
                    class="button-no-stlye"
                  >
                    <i
                      class="material-icons icon-iphone-plus"
                      style="vertical-align: sub;"
                    >
                      add
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="my-footer-button">
  <div class="row">
    <div class="col-12 offset-md-9 col-md-3" id="continue">
      <button
        type="button"
        class="btn btn-primary btn-lg btn-block"
        on:click={nextStage}
        style="background-color: {guestAppSettings.color};"
      >
        {$_("Continue")}
      </button>
    </div>
  </div>
</div>

<style>
  .selector-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  .selector-buttons {
    width: 97px;
    justify-content: space-between;
    display: flex;
    border: var(--color-letter) solid 1px;
    border-radius: calc(0.5 * 44px);
  }
  .button-no-stlye {
    border: none;
    background: none;
    font-size: 20px;
    width: 3rem;
  }
  .text-button-style {
    margin: auto;
    font-weight: 700;
    font-size: 14px;
  }
  .icon-iphone-plus {
    vertical-align: sub;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    margin-right: 0.5rem;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .icon-iphone-delete {
    vertical-align: sub;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
margin-left: 10% !important;
  }
  .description-text {
    font-size: 10px;
    color: #686c6d;
  }
  @media only screen and (min-width: 550px) and (max-width: 767px) {
    .button-no-stlye {
      border: none;
      background: none;
      font-size: 20px;
      width: 4rem;
    }
    .icon-iphone-plus{
    vertical-align: sub; display:flex; flex-direction: row-reverse;    justify-content: space-around;
  }
  .icon-iphone-delete {
    vertical-align: sub;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
  }
  }
</style>
