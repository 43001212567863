<script>
  import { onDestroy, onMount } from "svelte";
  import { handleAuthentication } from "./authService";
  import {
    activeRoute,
    params,
    page,
    queryParams,
    routerStop,
  } from "./Router.js";
  import { connectToEventSource } from "./events.services";

  import {
    addMessages,
    init,
    getLocaleFromNavigator,
    locale,
    json,
  } from "svelte-i18n";

  import HeaderComponent from "./header/header.component.svelte";
  import Error404Component from "./feature/error-404.svelte";
  import { httpGet } from "./http-service";
  import ReloadingComponent from "./components/reloading-component.svelte";
  import { environments } from "./assets/environments";

  import en from "./assets/en.json";
  import es from "./assets/es.json";
  addMessages("en", en);
  addMessages("es", es);

  init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
  });

  let loading = true;
  let ThemeMode = "white";
  let propertyInformation;
  let guestAppFeatures;
  let guestAppSettings;
  let disabled = false;

  if (localStorage.getItem("lang")) {
    locale.set(localStorage.getItem("lang"));
  } else {
    locale.set(getLocaleFromNavigator().split("-")[0]);
    localStorage.setItem("lang", getLocaleFromNavigator().split("-")[0]);
  }

  onMount(() => {
    params.subscribe(() => {
      disabled = true;
      setTimeout(() => (disabled = false), 10);
    });
    if ($queryParams.language) {
      localStorage.setItem("lang", $queryParams.language);
      locale.set($queryParams.language);
    }
    if ($queryParams.code) {
      localStorage.setItem("code", $queryParams.code);
    }

    if ($params.propertyId) {
      loading = true;
      locale.subscribe(() =>
        httpGet(`${environments.guestAppApi}/${$params.propertyId}/features`)
          .then((response) => response.json())
          .then(
            (guestAppFeaturesData) => (guestAppFeatures = guestAppFeaturesData)
          )
      );
      handleAuthentication($params.propertyId).then(() => {
        connectToEventSource({ propertyId: $params.propertyId });
        httpGet(
          `${environments.reservationsApi}/properties/${$params.propertyId}/guestapp-settings`
        )
          .then((response) => response.json())
          .then((guestAppSettingsData) => {
            loading = true;
            Promise.all([
              httpGet(
                `${environments.reservationsApi}/properties/${$params.propertyId}`
              ).then((response) => response.json()),
              httpGet(
                `${environments.guestAppApi}/${$params.propertyId}/features`
              ).then((response) => response.json()),
            ])
              .then(([propertyInformationData, guestAppFeaturesData]) => {
                guestAppFeatures = guestAppFeaturesData;
                guestAppSettings = guestAppSettingsData;
                propertyInformation = propertyInformationData;

                ThemeMode =
                  guestAppSettings.background == "#212121" ? "dark" : "white";

                loading = false;
              })
              .catch((err) => {
                loading = false;
              });
          });
      });
    } else {
      loading = false;
    }
  });

  onDestroy(routerStop);
</script>

<svelte:head>
  {#if ThemeMode == "dark"}
    <link rel="stylesheet" href="/vars-dark.css" />
  {:else}
    <link rel="stylesheet" href="/vars-default.css" />
  {/if}
  <link rel="stylesheet" href="/styles.css" />
</svelte:head>

{#if loading}
  <ReloadingComponent />
{:else if $activeRoute == "**" || !propertyInformation}
  <Error404Component />
{:else}
  <HeaderComponent
    {propertyInformation}
    bind:guestAppFeatures
    bind:guestAppSettings
  />

  <main>
    {#if disabled}
      <slot />
    {:else}
      <svelte:component
        this={page}
        {activeRoute}
        {params}
        {queryParams}
        {propertyInformation}
        {guestAppSettings}
        {guestAppFeatures}
      />
    {/if}
  </main>
{/if}
