import { environments } from "../assets/environments";
import { formDataPost, httpGet, httpPost, httpPut } from "../http-service";

const getWorkflowById = input =>
    httpGet(
        `${environments.guestAppApi}/${input.propertyId}/workflow/survey/${input.workflowId}${input.messageId ? `?messageId=${input.messageId}` : ''}`
    );

const sendMessageByWorkflowId = input =>
    httpPost(
        `${environments.guestAppApi}/${input.propertyId}/workflow/survey/${input.workflowId}${input.messageId ? `?messageId=${input.messageId}&channel=${input.channel}` : ''}`,
        input.body
    );

const markOpenMessageByWorkflowId = input =>
    httpPut(
        `${environments.guestAppApi}/${input.propertyId}/workflow/survey/${input.workflowId}${input.messageId ? `?messageId=${input.messageId}` : ''}`,
        input.body
    );

export const WorkflowServices = ({
    getWorkflowById,
    sendMessageByWorkflowId,
    markOpenMessageByWorkflowId
});