<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { uploadImgs } from "../check-in/service";
  import { _ } from "svelte-i18n";
  import ModalError from "./modal-error.svelte";
  import ReloadingComponent from "./reloading-component.svelte";
  export let guestAppSettings;
  export let propertyInformation;
  export let id = "0";
  export let data;
  export let message;
  export let isInvalid = false;
  let loading = false;
  let infoAlert = false;
  const dispatch = createEventDispatcher();

  function loadurl(item) {
    document.querySelector(`#upload${item}`).click();
  }

  function onImageUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    const pattern = /image-*/;

    if (!file.type.match(pattern)) {
      infoAlert = true;
      setTimeout(() => (infoAlert = false), 2000);
      return;
    }
    loading = true;
    dispatch("setValue", undefined)
    reader.readAsDataURL(file);
    reader.onload = () => {
      uploadImgs({
        data: reader.result,
        propertyId: propertyInformation.id,
      })
        .then((response) =>
          response.ok ? response.json() : Promise.reject(response)
        )
        .then((res) => {
          dispatch("setValue", res.urlToPreview)
          loading = false;
        });
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
      document.querySelector(`#upload${id}`).value = "";
      loading = false;
    };
  }
</script>

<section>
  <div
    class="card-body alternate {data === '' && isInvalid ? 'alt-invalid' : ''}"
    on:keydown={null}
    on:click={() => loadurl(id)}
  >
    <div class="imagen-upload">
      {#if loading}
        <ReloadingComponent bind:guestAppSettings />
      {/if}
      {#if data}
        <img class="card-photo" src={data} alt="" />
      {:else}
        <span class="text-upload-imagen">
          {!loading ? $_(message) : ' '}
        </span>
      {/if}
      <div class="card-icons">
        <button class="icono-round" on:click={() => null}>
          <i class="material-icons">
            {#if data}
              edit
            {:else}
              photo_camera
            {/if}
          </i>
        </button>
      </div>
    </div>
    <input
      id="upload{id}"
      class="file-companion"
      name="upload{id}"
      type="file"
      accept="image/*"
      on:change={(event) => onImageUpload(event)}
    />
  </div>
  {#if infoAlert}
    <ModalError message={$_("Invalid format")} />
  {/if}
</section>

<style>
  .imagen-upload {
    cursor: pointer;
  }
  .imagen-upload .text-upload-imagen {
    display: block;
    margin: 25% auto;
    text-align: center;
    min-height: 28.5px;
  }
  .imagen-upload img {
    display: block;
    margin: 0% auto;
  }
  .card-photo {
    inline-size: fit-content;
    inline-size: inherit;
    width: 100%;
  }
  .card-icons {
    display: flex;
    float: right;
  }
  .alternate {
    border-radius: calc(0.5 * 13px);
    background-color: var(--back-person) !important;
  }
  .alt-invalid {
    border: solid 1px #ff4855;
  }
  .file-companion {
    display: none;
  }
</style>
