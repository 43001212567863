<script>
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import { params, redirect } from "../Router";
  import {
    headerInformation,
    openLogin,
    backInCurrentPage,
    goBack,
    showMenu,
    onClickBackEvent,
  } from "../header/service";
  import { isAuthenticated, logout } from "../authService";
  import LoginComponent from "../login/login.component.svelte";
  import ModalError from "../components/modal-error.svelte";

  export let ThemeMode = "";
  export let propertyInformation;
  export let guestAppFeatures;
  export let guestAppSettings;
  let connectOffline = false;

  onMount(() => {
    document
      .querySelector(".my-overlay-sidebar")
      .addEventListener("click", (e) => {
        document.querySelector("#collapseExample").classList.remove("show");
      });
    const styleSettings = {
      "--dynamic-color": guestAppSettings.color,
      "--colorLetter": guestAppSettings.colorLetter,
      "--background": guestAppSettings.background,
      "--font-family": guestAppSettings.fontFamily,
    };
    Object.keys(styleSettings).forEach((key) =>
      document.querySelector(":root").style.setProperty(key, styleSettings[key])
    );
    window.addEventListener("offline", () => (connectOffline = true));
    window.addEventListener("online", () => (connectOffline = false));

    params.subscribe(() => closeSidebar());
  });

  const closeSidebar = () =>
    document.querySelector("#collapseExample").classList.remove("show");

  const changeLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    locale.set(lang);
  };
</script>

<nav class="navbar navbar-expand-md fixed-top">
  <div class="container-fluid">
    <ul class="navbar-nav ml-auto">
      {#if $goBack}
        <a
          type="button"
          class="button__circle"
          href="#!"
          id="goBack"
          on:click|preventDefault={() =>
            !$backInCurrentPage
              ? redirect($goBack)
              : onClickBackEvent.set(Date.now)}
        >
          <i class="material-icons">keyboard_arrow_left</i>
        </a>
      {/if}
    </ul>

    <ul class="navbar-nav mx-auto">
      {#if $headerInformation}
        <a class="navbar-brand" href="#!">
          <img
            src={$headerInformation.logo}
            alt=""
            class={$headerInformation.logo.includes(".svg") && !$headerInformation.logo.includes("default.svg")
              ? "nav-logo" + " " + "svg-color-" + guestAppSettings.color.replace("#", "")
              : "" + " nav-logo"}
          />
          <span class="navbar-brandName">
            {$_($headerInformation.title).slice(0, 20)}
            {$headerInformation.title.length > 20 ? "..." : ""}
          </span>
        </a>
      {/if}
    </ul>

    {#if $showMenu}
      <ul class="navbar-nav mr-auto">
        <a
          href="#!"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="material-icons">menu</i>
        </a>
      </ul>
    {:else}
      <ul class="navbar-nav mr-auto">
        <div class="dropdown iconLangSelect">
          <a
            class="nav-link dropdownLang"
            href="#!"
            id="navbarDarkDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="material-icons iconLang"> public </i>
            {#if $locale == "es"}
              <span>
                {$_("Es")}
              </span>
            {:else}
              <span>
                {$_("En")}
              </span>
            {/if}
          </a>
          <ul
            style="list-style: none; min-width: 5rem; "
            class="dropdown-menu"
            aria-labelledby="navbarDarkDropdownMenuLink"
          >
            <li>
              <a
                class="dropdown-item"
                href="#!"
                on:click|preventDefault={() => changeLanguage("en")}
                >{$_("English")}</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#!"
                on:click|preventDefault={() => changeLanguage("es")}
                >{$_("Spanish")}</a
              >
            </li>
          </ul>
        </div>
      </ul>
    {/if}
  </div>
</nav>

<aside class="collapse" id="collapseExample">
  <div class="my-overlay-sidebar" />
  <div
    class="d-flex flex-column flex-shrink-0 p-3 sidebar-gh mod-scroll"
    style="overflow-y: auto; overflow-x: hidden;"
  >
    <div
      class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none"
    >
      <span class="fs-4">{$_("Welcome")}</span>
      <a
        type="button"
        class="button__circle"
        style="position: absolute; right: 10px"
        href="#!"
        on:click|preventDefault={closeSidebar}
      >
        <i class="material-icons">close</i>
      </a>
    </div>
    <hr class="hr-pos" />
    <ul class="nav nav-pills flex-column mb-auto">
      {#if guestAppFeatures.find((feature) => feature.codename == "requests.create")}
        <li class="nav-item">
          <a
            href="/{propertyInformation.id}/{guestAppFeatures.find(
              (feature) => feature.codename == 'requests.create'
            ).id}"
            class="btn btn-block btn-lg btn-primary"
            style="background-color: {guestAppSettings.color};"
            aria-current="page"
          >
            {$_(
              guestAppFeatures.find(
                (feature) => feature.codename == "requests.create"
              ).title
            )}
          </a>
        </li>
      {/if}
      <li class="nav-item">
        <a
          href="/{propertyInformation.id}"
          class="nav-link"
          aria-current="page"
        >
          {$_("Home")}
        </a>
      </li>
      <li class="nav-item">
        <a
          href="/{propertyInformation.id}/check-in"
          class="nav-link"
          aria-current="page"
        >
          {$_("Check in")}
        </a>
      </li>
      {#each guestAppFeatures as feature}
        {#if feature.codename != "requests.create"}
          <li class="nav-item">
            <a
              href="/{propertyInformation.id}/{feature.id}"
              class="nav-link"
              aria-current="page"
            >
              {$_(feature.title)}
            </a>
          </li>
        {/if}
      {/each}
      <li class="nav-item">
        <hr class="hr-pos" />
      </li>
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#!"
          id="navbarDarkDropdownMenuLink"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {#if $locale == "es"}
            {$_("Spanish")}
          {:else}
            {$_("English")}
          {/if}
        </a>
        <ul
          class="dropdown-menu dropdown-menu-dark"
          aria-labelledby="navbarDarkDropdownMenuLink"
        >
          <li>
            <a
              class="dropdown-item"
              href="#!"
              on:click|preventDefault={() => changeLanguage("en")}
              >{$_("English")}</a
            >
          </li>
          <li>
            <a
              class="dropdown-item"
              href="#!"
              on:click|preventDefault={() => changeLanguage("es")}
              >{$_("Spanish")}</a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item">
        {#if $isAuthenticated}
          <a
            href="#!"
            class="nav-link"
            aria-current="page"
            on:click|preventDefault={() => {
              document
                .querySelector("#collapseExample")
                .classList.remove("show");
              logout();
            }}
          >
            {$_("Logout")}
          </a>
        {:else}
          <a
            href="#!"
            class="nav-link"
            aria-current="page"
            on:click|preventDefault={() => openLogin.set(true)}
          >
            {$_("Login")}
          </a>
        {/if}
      </li>
    </ul>
    <!-- <hr /> -->
    <div class="d-flex align-items-center text-white text-decoration-none">
      <strong>{$_("Powered by")} </strong>
      <img
        src="/logo-guesthub{ThemeMode === 'dark' ? '-dark.svg' : '.png'}"
        alt="Logo GuestHub"
        height="22"
        class="me-2"
        style="margin-left: 10px;"
      />
    </div>
  </div>
</aside>

{#if $openLogin}
  <LoginComponent
    {propertyInformation}
    on:successful={() =>
      document.querySelector("#collapseExample").classList.remove("show")}
    on:onClose={() => openLogin.set(false)}
  />
{/if}
{#if connectOffline}
  <ModalError
    message={$_("The connection has been lost.")}
    action={$_("Update")}
  />
  <div class="modal-overlay ups" />
{/if}

<style>
  .hr-pos {
    position: relative;
    border-top: 5px solid;
    width: 117%;
    left: -15px;
  }

  .dropdownLang {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    width: 36px;
    height: 36px;
    margin-right: 8px !important;
    cursor: pointer;
    text-decoration: none;
  }

  .dropdown-menu {
    background-color: var(--background-color);
    top: 100%;
    left: -50px !important;
    position: absolute;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: var(--color-letter);
    background-color: var(--background-color);
  }
  .dropdown-menu .dropdown-item {
    color: var(--color-letter);
  }
</style>
