
import { writable, derived } from "svelte/store";
import { environments } from "./assets/environments";
import { Login } from "./login/service";

export const isAuthenticated = writable(false);
export let user = null;
export let token = null;
export let browserIdentify = null;

export const setUser = input => {
  localStorage.setItem('code', input.code);
  user = input;
}

export function handleAuthentication(propertyId) {
  isAuthenticated.set(false);
  user = null;
  token = null;
  if (!localStorage.getItem('browserIdentify')) {
    localStorage.setItem('browserIdentify', Date.now());
  }
  browserIdentify = localStorage.getItem('browserIdentify');

  if (localStorage.getItem('code')) {
    return Login({
      propertyId: propertyId,
      code: localStorage.getItem('code'),
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      )
      .then((response) => {
        isAuthenticated.set(true);
        setUser(response);
        return Promise.resolve(response);
      })
      .catch((err) => {
        console.error(err);
        return Promise.resolve(null);
      });
  } else {
    return Promise.resolve(false);
  }
}

export function logout() {
  localStorage.removeItem('code');
  isAuthenticated.set(false);
  user = null;
  token = null;
}
