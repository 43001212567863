<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";
  import { headerInformation } from "../header/service";
  const dispatch = createEventDispatcher();
  export let termBody = "";
</script>

<section class="container-fluid" style="padding-top: 10px">
  <ul class="navbar-nav ml-auto">
    <a
      type="button"
      class="button__circle"
      href="#!"
      on:click|preventDefault={() => dispatch("closeTerms", undefined)}
    >
      <i class="material-icons">keyboard_arrow_left</i>
    </a>
  </ul>

</section>
<section class="container">
  <h1 class="term__title">{termBody.title}</h1>
  {@html termBody.body}
</section>

<style>

@media only screen and (min-width: 20px) {
    .term__title {
      margin-bottom: 30px;
      margin-top: 80px;
      font-weight: bold;
    }
  }

  @media only screen and (min-width: 20px) and (max-width: 633px){
    .container{
      margin-top: -60px !important;
    }
  }
  
</style>
