<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import ReloadingComponent from "../../components/reloading-component.svelte";
  import { headerInformation, goBack } from "../../header/service";
  import { getRequests } from "../service";
  import { format } from "date-fns";
  import { redirect } from "../../Router";
  import { environments } from "../../assets/environments";

  export let propertyInformation;
  let loading = true;
  let requests = [];

  onMount(() => {
    initComponent();
  });

  const initComponent = () => {
    loading = true;
    goBack.set(`/${propertyInformation.id}`);
    headerInformation.set({
      title: propertyInformation.name,
      logo: propertyInformation.logo,
    });
    if (!window.location.host.includes("localhost")) {
      window.location.href = environments.reservationsWeb
        .concat("/#/properties/")
        .concat(propertyInformation.id)
        .concat("/");
    }
    getRequests({
      propertyId: propertyInformation.id,
    })
      .then((response) => response.json())
      .then((requestsData) => {
        requests = requestsData;

        loading = false;
      });
  };

  const goToRequestDetail = (request) => () => {
    redirect(`/${propertyInformation.id}/my-request/${request.id}`);
  };
</script>

{#if loading}
  <ReloadingComponent />
{:else}
  <section class="container">
    {#each requests as request}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="row" on:click|preventDefault={goToRequestDetail(request)}>
        <div class="col-6">{request.type} {request.statusCodename}</div>
        <div class="col-6">
          {format(new Date(request.executionDate), "yyyy MM dd HH:mm")}
        </div>
      </div>
    {/each}
  </section>
{/if}
