export function limitField(ev, regex) {
    if (ev.target.value === ' ') ev.target.value = ev.target.value.replace(' ', '')
    if (regex && ev.target.value.match(regex)) ev.target.value = ev.target.value.replace(regex, "");
}

export function checkInvalidForm(field) {
    if (!field) return
    field.valid = field?.elements[0].validity.valid
    return field
}

export function omit(key, obj) {
    const { [key]: _, ...rest } = obj;
    return rest;
}