<script>
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  export let propertyInformation;
  export let guestAppSettings;
  export let readOnly;
  const dispatch = createEventDispatcher();

  const nextStage = () =>
    !readOnly ? dispatch("nextStage", "nextStage") : null;
</script>

<section class="container">
  <div class="my-container-check-in">
    <img src={propertyInformation.logo} alt={propertyInformation.name} />
    <h1>{$_("Check in")}</h1>
    <p>
      {$_("Welcome parraph1").replace("#$", propertyInformation.name)}
    </p>
    <p>
      {$_("welcome parraph2")}
    </p>
  </div>
</section>

<div class="my-footer-button">
  <div class="row">
    <div class="col-12 offset-md-9 col-md-3">
      <button
        type="button"
        class="btn btn-primary btn-lg btn-block"
        style="background-color: {guestAppSettings.color};"
        on:click={nextStage}
      >
        {$_("Continue")}
      </button>
    </div>
  </div>
</div>

<style>
  .my-container-check-in {
    position: absolute;
    margin: 0px;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 65%;
    transform: translateY(-50%) translateX(-50%);
  }
  .my-container-check-in img {
    border-radius: 5px;
    margin-bottom: 20px;
    max-width: 200px;
  }
  @media(max-width: 720px){
    .my-container-check-in{
      width: 90%;
    }
  }
  @media(max-height: 420px){
    .my-container-check-in{
      padding-top: 36%;
      padding-bottom: 12%;
    }
  }
</style>
