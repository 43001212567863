<script>
    import { createEventDispatcher, onMount } from "svelte";
    import { useForm, required, validators, Hint } from "svelte-use-form";
    import { _ } from "svelte-i18n";
    import { Login } from "./service";
    import { isAuthenticated, setUser } from "../authService";

    const dispatch = createEventDispatcher();
    const dataForm = useForm();
    let disabledButton = false;

    export let propertyInformation;
    const codes = ["", "", "", "", ""];

    onMount(() => {
        document
            .querySelector(".my-overlay-sidebar2222")
            .addEventListener("click", (e) => {
                closeModal();
            });
    });

    const closeModal = () => dispatch("onClose", true);

    const eventWrite = (i) => (e) => {
        if (e.target.value.toString().trim().length > 0) {
            if (i >= 0 && i < codes.length - 1) {
                document.getElementById(`code${i + 1}`).focus();
            }
        } else {
            if (i > 0 && i <= codes.length - 1) {
                document.getElementById(`code${i - 1}`).focus();
            }
        }
    };

    const onKeyPress = (i) => (e) => {
        if (e.target.value.toString().trim().length > 0) {
            if (i >= 0 && i < codes.length - 1) {
                document.getElementById(`code${i + 1}`).focus();
            }
        }
    };

    const submitLogin = () => {
        disabledButton = true;
        Login({
            propertyId: propertyInformation.id,
            code: codes.join(""),
        })
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response)
            )
            .then((response) => {
                isAuthenticated.set(true);
                setUser(response);
                dispatch("successful", response);
                closeModal();
            })
            .catch((err) => {
                console.error(err);
                disabledButton = false;
            });
    };
</script>

<div class="my-overlay-sidebar my-overlay-sidebar2222 overlay-visible" />
<div class="modal modal-login">
    <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        on:click={closeModal}
    />

    <div class="modal-content">
        <div class="logo-property">
            <img
                src={propertyInformation.logo}
                alt={propertyInformation.name}
            />
        </div>

        <h5 class="modal-title">
            {propertyInformation.name}
        </h5>

        <p class="modal-description">
            {$_("Enter the validation code that was sent to your email")}
        </p>

        <form use:dataForm on:submit|preventDefault={submitLogin}>
            {#if $dataForm.location?.touched && $dataForm.location?.errors.required}
                {#if $dataForm.location.errors.required}
                    Debes selecciónar un país.
                {/if}
            {/if}

            <div class="input-group">
                {#each codes as code, i (i)}
                    <!-- svelte-ignore a11y-autofocus -->
                    <input
                        use:validators={[required]}
                        class="form-control"
                        type="text"
                        id="code{i}"
                        name="code{i}"
                        maxlength="1"
                        minlength="1"
                        required
                        autofocus={i == 0 ? true : false}
                        autocomplete="no"
                        on:input={eventWrite(i)}
                        on:keypress={onKeyPress(i)}
                        bind:value={code}
                    />
                {/each}
            </div>

            <a href="#!" class="btn btn-link btn-block">
                {$_("I don’t have a validation code")}
            </a>

            <button
                type="submit"
                disabled={!$dataForm.valid || disabledButton}
                class="btn btn-primary"
            >
                {$_("Continue")}
            </button>
        </form>
    </div>
</div>

<style>
    .modal-login {
        z-index: 9;
        display: block;
        position: fixed;
        background-color: var(--card-background-color);
        background-clip: padding-box;
        border: 1px solid var(--card-background-color);
        border-radius: 0.3rem;
        outline: 0;
        color: var(--color-letter);
    }

    .modal-login .modal-content {
        background-color: var(--background-color);
        width: 40%;
        margin: 20% auto 0 auto;
        border-radius: 20px 20px 20px 20px;
    }
    .modal-login .modal-content .logo-property {
        position: relative;
        text-align: center;
        margin-top: -50px;
    }

    .input-group input[type="text"] {
        margin: 10px;
    }
</style>
